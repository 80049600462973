import React, { useState } from 'react';
import { format, addDays } from 'date-fns';
import styles from './DatePicker.module.scss';
import { useDateRange } from '../../context/ DateRangeContext'; // Import the context hook
const DateRangePicker = () => {
  const { selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate } = useDateRange();

  const handleStartDateChange = (date) => {
    // Set the time component to the start of the day (00:00:00.000)
    date.setHours(0, 0, 0, 0);
    setSelectedStartDate(date); // Update the selected start date in the context
  };

  const handleEndDateChange = (date) => {
    // Set the time component to the end of the day (23:59:59.999)
    date.setHours(23, 59, 59, 999);
    setSelectedEndDate(date); // Update the selected end date in the context
  };


  // const handleStartDateChange = (date) => {
  //   setStartDate(date);
  // };

  // const handleEndDateChange = (date) => {
  //   setEndDate(date);
  // };
  
  return (
    <div className={styles.formIn}>
      <div className={styles.formGroup}>
      <label>Start Date <span></span></label>
        <input className={styles.fromControl}
          type="date"
          id="start-date"
          value={format(selectedStartDate, 'yyyy-MM-dd')}
          onChange={(e) => handleStartDateChange(new Date(e.target.value))}
        />
      </div>
      
      
      <div className={styles.formGroup}>
        <label>End Date<span></span></label>
        <input className={styles.fromControl}
          type="date"
          id="end-date"
          value={format(selectedEndDate, 'yyyy-MM-dd')}
          onChange={(e) => handleEndDateChange(new Date(e.target.value))}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
