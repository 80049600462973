  import React, { useState, useEffect } from 'react';
  import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
  import styles from './Sidebar.module.scss';
  import { Collapse } from '@mui/material';
  import companyLogo from '../../../assets/images/logo.svg';
  import dashboardIcon from '../../../assets/images/dashboardIcon.svg';
  import jobs from '../../../assets/images/jobsListup.svg';
  import offerLetter from '../../../assets/images/offerLetterNav.svg';
  import analyticImage from '../../../assets/images/analytic.svg';
  import candidateNe from '../../../assets/images/candidate.svg';
  import uidImage from '../../../assets/images/userID.svg';
  import leaderShipImg from '../../../assets/images/leadership.svg'

  const Sidebar = ({ userData }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState({
      jobs: false,
      candidates: false,
      offers: false,
      analytics: false,
    });
    
      const minimumValue = 0;
      const maximumValue = 1000;
      const liveJobColor = "#00C49F";
      const pendingJobColor = "#0088FE";
      const suspendedJobColor = "#FFBB28";
      const closedJobColor = "#FF8042";

      const triggerCollapse = (type) => {
        setIsOpen((prevState) => ({ ...prevState, [type]: true }));
        if (type === 'jobs') {
          navigate('/jobs/live');
        } 
      };

    return (
      <div className={styles.mainDiv}>
        <div className={styles.mainMenu}>
          <header className={styles.header}>
            <a href="#" className={styles.logo}>
              <img src={companyLogo} alt="Company Logo" />
            </a>
          </header>
          <div className={styles.content}>
            <div className={styles.navigation}>
              <ul className={styles.Menu}>
                <li >
                  <Link to="/dashboard" className={styles.wavesEffect} style={(pathname === '/dashboard' || pathname === "/") ? { color: "#ff6600"} : {}}>
                    <img src={dashboardIcon} className={`${styles.dashboardIcon} ${(pathname === '/dashboard' || pathname === "/") ? styles.dashboardIconActive : ""}`} alt=""  />
                    <span className={styles.spanHide}>Home</span>
                  </Link>
                </li>
                <li>
                  <Link to="/employers" className={styles.wavesEffect} style={(pathname === '/employers' ) ? { color: "#ff6600"} : {}}>
                    <img src={candidateNe} className={`${styles.candidateIcon} ${isOpen.candidates ? styles.candidateIconActive : ""}`}  alt="Candidates Icon" />
                    <span className={styles.spanHide}>Employers</span>
                  </Link>
                </li>
                <li >
                  {/* <Link to="/joblist/Live"  className={styles.wavesEffect} onClick={() => triggerCollapse('jobs')} style={(pathname === '/Live') ? { color: "#ff6600"} : {}}>
                    <img src={jobs} className={`${styles.jobsIcon} ${isOpen.jobs ? styles.jobsIconActive : ""}`} alt="" />
                    <span>Jobs</span>
                  </Link> */}
                  <Link to="#" className={styles.wavesEffect} onClick={() => triggerCollapse('jobs')}style={(pathname === '/Live') ? { color: "#ff6600"} : {}}>
                      <img src={jobs} className={`${styles.jobIcon} ${isOpen.jobs ? styles.jobIconActive : ""}`} alt="" />
                      <span className={styles.spanHide}>Jobs</span>
                    </Link>
                  <Collapse in={isOpen.jobs} timeout={1000}>
                    <div>
                    <NavLink to="/joblist/Live" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#ff6600", background: "#222E4E" } : {}}>
                      <div className={styles.sideSpace}>
                        <span className={styles.dotStyle} style={{ background: "#1ABC9C" }}></span>Live Jobs
                      </div>
                    </NavLink>
                      <NavLink to="/joblist/Pending" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#ff6600", background: "#222E4E" } : {}}>
                        <div className={styles.sideSpace}>
                          <span className={styles.dotStyle} style={{ background: "#3498DB" }}></span>Pending
                        </div>
                      </NavLink>
                      <NavLink to="/joblist/Suspended" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#ff6600", background: "#222E4E" } : {}}>
                        <div className={styles.sideSpace}>
                          <span className={styles.dotStyle} style={{ background: "#F1C40F" }}></span>Suspend
                        </div>
                      </NavLink>
                      <NavLink to="/joblist/Closed" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#ff6600", background: "#222E4E" } : {}}>
                        <div className={styles.sideSpace}>
                          <span className={styles.dotStyle} style={{ background: "#E74C3C" }}></span>Closed
                        </div>
                      </NavLink>
                    </div>
                  </Collapse>
                </li>
                <li>
                  <Link to="/candidate" className={styles.wavesEffect} style={(pathname === '/candidate' || pathname === "/") ? { color: "#ff6600"} : {}}>
                    <img src={candidateNe} className={`${styles.candidateIcon} ${isOpen.candidates ? styles.candidateIconActive : ""}`}  alt="Candidates Icon" />
                    <span>Candidates</span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className={styles.wavesEffect}>
                    <img src={offerLetter} className={`${styles.offerLet} ${isOpen.offers ? styles.offerLetActive : ""}`}  alt="Offer Letter Icon" />
                    <span>Offer Letters</span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className={styles.wavesEffect}>
                    <img src={analyticImage} className={`${styles.analyticsImg} ${isOpen.offers ? styles.analyticsImgActive : ""}`} alt="Analytics Icon" />
                    <span>Analytics</span>
                  </Link>
                </li>
                <li>
                  <Link to="/UIDSearch" className={styles.wavesEffect} style={(pathname === '/UIDSearch' || pathname === "/") ? { color: "#ff6600"} : {}}>
                    <img src={uidImage} className={`${styles.uidImg} ${isOpen.offers ? styles.uidImgActive : ""}`} alt="Analytics Icon" />
                    <span>UID Search</span>
                  </Link>
                </li>
                <li>
                  <Link to="/leaderShipBoard" className={styles.wavesEffect} style={(pathname === '/leaderShipBoard' || pathname === "/") ? { color: "#ff6600"} : {}}>
                    <img src={leaderShipImg} className={`${styles.leaderImg} ${isOpen.offers ? styles.leaderImgActive : ""}`} alt="Analytics Icon" />
                    <span>Leadership</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Sidebar;
