import React, { useState } from 'react';
import styles from './Login.module.scss';
import { toast } from 'react-toastify';
import { showLoader, hideLoader, setCookie } from '../../helpers/index';
import { Container, Row, Col } from 'react-bootstrap';
import mainMap from '../../assets/images/mainIndia.svg';
import satImage from '../../assets/images/satImage.svg';
import companyLogo from '../../assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import whatImage from '../../assets/images/user.svg';
import passImage from '../../assets/images/passlock.svg';
import { useForm } from 'react-hook-form';
import { loginRequest } from '../../services/api/admin';

function Login() {
  const navigate = useNavigate();
  const [loginCredentials, setLoginCredentials] = useState({
    email: '',
    password: '',
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginCredentials({ ...loginCredentials, [name]: value });

      // Email validation
      if (name === 'email') {
        const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        setEmailError(emailPattern.test(value) ? '' : 'Invalid email address');
      }
  
      // Password validation
      if (name === 'password') {
        setPasswordError(value.trim() ? '' : 'Password is required');
      }
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (!loginCredentials.email.trim()) {
        setEmailError('Email is required');
        return;
      }
  
      if (!loginCredentials.password.trim()) {
        setPasswordError('Password is required');
        return;
      }
  
    try {
      let submitData = loginCredentials;
      showLoader();
      const {
        data: {
          data, status
        }
      } = await loginRequest(loginCredentials);
      if (status === 200 && data.refreshToken) {
        hideLoader();
        toast.success('Sign-In Successful');
        var now = new Date();
        now.setTime(now.getTime() + 8 * 3600 * 1000); // Expire time 8 hours
        let cookieOptions = {
          expires: now, // seconds to expire
        };
        setCookie('token', data.refreshToken, cookieOptions);
        let adminData = {
          _id: data._id,
          fullName: data.fullName,
          whatsappMobile: data.whatsappMobile,
        };
        
        localStorage.setItem('userData', JSON.stringify(adminData));
        navigate('/dashboard');
      }
    } catch (error) {
      
      const message = error.data.message || 'Failed, Try again';
      console.log(message)
      hideLoader();
      toast.error(message);
    }
  }

  return (
    <section className={styles.mainLogin}>
      <Container className={styles.mainContainer}>
        <Row className={styles.flexBox}>
          <Col md={6} className={styles.sideImage}>
            <div className={styles.newSlide}>
              <img src={mainMap} alt="Main Map" />
              <div className={styles.satImg}>
                <img src={satImage} alt="Satellite Image" />
              </div>
            </div>
          </Col>
          <Col md={6} className={styles.formDetail}>
            <div className={styles.sideFrom}>
              <div className={styles.headSec}>
                <img src={companyLogo} alt="Company Logo" />
                <p>Login with your Email Address</p>
              </div>

              <form className={styles.formWidth}>
                <div className={styles.frmInput}>
                  <input
                    type="text"
                    name="email"
                    className={styles.frmInp}
                    placeholder="Email"
                    value={loginCredentials.email}
                    onChange={handleInputChange}
                  />
                  {emailError && <div className={styles.error}>{emailError}</div>}
                  {/* <img src={whatImage} alt="Email Icon" className={styles.frmIco} /> */}
                </div>
                <div className={styles.frmInput}>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={loginCredentials.password}
                    onChange={handleInputChange}
                    className={styles.frmInp}
                  />
                  {passwordError && <div className={styles.error}>{passwordError}</div>}
                  {/* <img src={passImage} alt="Password Icon" className={styles.frmIco} /> */}
                </div>

                <div className={styles.btnNew}>
                  <div className={styles.effNew}></div>
                  <button onClick={(e) => handleLogin(e)}>Login</button>
                </div>
                <p className={styles.notMember}>Not a member? Don't worry!<br /> Just fill your WhatsApp contact and we will sign you up quickly. </p>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
      <Container maxwidth="sm" className={styles.newFoot}>
        <Row>
          <Col md={12}>
            <div className={styles.footerHead}>
              <p>© 2023 Jobsgaar. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Login;
