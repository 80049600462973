import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Proimg from '../../assets/images/userPro.jpeg';
import videoIcon from '../../assets/images/videoIcon.svg';
import userClose from '../../assets/images/close.svg';
import userCheck from '../../assets/images/check.svg';
import styles from './Candidate.module.scss';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';

function CandidateDetail() {
 

  return (
    <HomeLayout>
      <div className={styles.mainContent}>
        <Container>
          
          <Row>
            <Col md={12}>
              <div className={styles.candateDetail}>
                <div className={styles.detailCand}>
                    <Row>
                        <Col md={2}>
                            <div className={styles.cadiPro}>
                                <div className={styles.rankNew}>
                                    <span>10<br/>
                                        <span className={styles.slashName}>/</span>10
                                    </span>
                                </div>
                                <img src={Proimg}/>
                                <h3>Arun Kumar</h3>
                            </div>
                        </Col>
                        <Col md={10}>
                            <div className={styles.detailNew}>
                                <ul>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Experience</span>3-4 Years</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Education</span>10th - 12th Class</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Current Salary</span>₹ 25000</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Expected Salary</span>₹ 14000</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>City</span>Jiribam</h3>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Birth Day</span>30 Sep' 93</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Age</span>30</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Gender</span>Male</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Degree</span>Bachelor of Technology / Engineering (BTech) / (BE)</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Branch / Stream</span>Computer Science Engineering</h3>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Resume</span>
                                                <a rel="noreferrer"  target="_blank" className="pointer">
                                                    <img src={userCheck} alt='' /> Document
                                                </a>
                                            </h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Identity</span>
                                                <ul>
                                                    <li>
                                                        <img src={userCheck} alt='' />
                                                        <a target="_blank" className="pointer"> Pan Card</a>
                                                    </li>
                                                </ul>
                                            </h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.dtatList}>
                                            <h3><span>Intro Video</span>
                                                <ul>
                                                    <li>
                                                        <img src={videoIcon} alt='' />
                                                        <a target="_blank" className="pointer"> Video</a>
                                                    </li>
                                                </ul>
                                            </h3>
                                        </div>
                                    </li>
                                    <li style={{width: "40%"}}>
                                        <div className={styles.dtatList} >
                                            <h3 className={styles.dldrive}>
                                                <span>Driving Requirements</span>
                                                <ul>
                                                <li ><img src={userCheck} alt='' />Driver’s License</li>
                                                <li><img src={userClose} alt='' />Motorcycle/Scooty </li>
                                                <li style={{display: "block"}}><img src={userCheck} alt='' />Car</li>
                                                </ul>
                                            </h3>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    <li style={{width: "40%"}}>
                                        <div className={styles.dtatList}>
                                            <h3>
                                                <span>Desired Designation</span>
                                            Driver - Cab/Commercial Vehicle
                                            </h3>
                                        </div>
                                    </li>
                                    <li style={{width: "60%"}}>
                                        <div className={styles.dtatList}>
                                            <div className={styles.specCode}>
                                                <span>Specialization</span>
                                                <button className={styles.spList}>Commercial DL</button>
                                                <button className={styles.spList}>GPS Navigation</button>
                                                <button className={styles.spList}>LMV License</button>
                                                <button className={styles.spList}>HPMV License</button>
                                                <button className={styles.spList}>Electric Vehicle</button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.expDetail}>
                                <h3>Past & Current Experiences</h3>
                                <ul>
                                    <li>
                                    <div className={styles.expData}>
                                        <h2>Doodleblue</h2>
                                            <ul>
                                                <li><span>Profile:</span><h3>Backend Developer</h3></li>
                                                <li><span>Salary:</span><h3>₹25000</h3></li>
                                                <li><span>Duration:</span><h3>Dec-2001 - Nov-2003</h3></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </HomeLayout>
  );
}

export default CandidateDetail;
