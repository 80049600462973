import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import styles from './DataTable.module.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown} from 'primereact/dropdown';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { CustomerService } from '../../service/CandidateServiceList';
import '../../../../src/flags.css';
import { NavLink } from 'react-router-dom';


export default function AdvancedFilterDemo() {
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
   
    const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);
   
    const getSeverity = (status) => {
        switch (status) {
            case 'unqualified':
                return 'danger';

            case 'qualified':
                return 'success';

            case 'new':
                return 'info';

            case 'negotiation':
                return 'warning';

            case 'renewal':
                return null;
        }
    };

    useEffect(() => {
        CustomerService.getCustomersLarge().then((data) => {
            setCustomers(getCustomers(data));
            setLoading(false);
        });
        initFilters();
    }, []);

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.createdAt = new Date(d.createdAt);
            return d;
        });
    };
    

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            city: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            whatsappMobile: { value: null, matchMode: FilterMatchMode.IN },
            aadhaarMobile: { value: null, matchMode: FilterMatchMode.IN },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            desiredDesignation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
          
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="btlFilterClr justify-content-between">
                <Button className={styles.btnCler} type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText className={styles.formCon} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                {/* <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} /> */}
                <span>{rowData.city}</span>
            </div>
        );
    };

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} severity="secondary"></Button>;
    };

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
    };

    const filterFooterTemplate = () => {
        return <div className="px-3 pt-0 pb-3 text-center">Filter by Country</div>;
    };


    const dateBodyTemplate = (rowData) => {
        if (rowData.createdAt) {
            const date = new Date(rowData.createdAt);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return '';
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };
    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.verifiedEmployer} severity={getSeverity(rowData.verifiedEmployer)} />;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };


     
      const fullNameColumn = (
        <Column
          field="fullName"
          header="Name"
          filter
          filterPlaceholder="Search by name"
          style={{ minWidth: '12rem' }}
          body={(rowData) => (
            <NavLink to={`/candidatedetail/${rowData._id}`} className={styles.link}>
              {rowData.fullName}
            </NavLink>
          )}
        />
      );
    const header = renderHeader();
    
    return (
        <div className="card">
          <DataTable
            value={customers}
            paginator
            showGridlines
            rows={10}
            loading={loading}
            dataKey="id"
            filters={filters}
            globalFilterFields={['fullName', 'city', 'whatsappMobile', 'createdAt', 'aadhaarMobile', 'desiredDesignation']}
            header={header}
            emptyMessage="No records found."
          >
         
           {fullNameColumn}
            <Column field="whatsappMobile" header="Number" filter filterPlaceholder="Search by number" style={{ minWidth: '12rem' }} />
            <Column field="aadhaarMobile" header="Sec. Number" filter filterPlaceholder="Search by number" style={{ minWidth: '12rem' }} />
            <Column header="city" filterField="city" style={{ minWidth: '12rem' }} body={countryBodyTemplate}
              filter filterPlaceholder="Search by country" filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate} filterFooter={filterFooterTemplate} />
            <Column field="desiredDesignation" header="Designation" filter filterPlaceholder="Search by desiredDesignation " style={{ minWidth: '12rem' }} />
            <Column header="createdAt" filterField="createdAt" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
            
           
          </DataTable>
        </div>
      );
    }
