import React, { createContext, useContext, useState } from 'react';
import { format, addDays } from 'date-fns';
const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {


const today = new Date();
today.setHours(0, 0, 0, 0); // Set to midnight

const endOfDay = new Date(today);
endOfDay.setHours(23, 59, 59, 999); 
    const [selectedStartDate, setSelectedStartDate] = useState(today);
    const [selectedEndDate, setSelectedEndDate] = useState(endOfDay);

  return (
    <DateRangeContext.Provider value={{ selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate }}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRange = () => {
  return useContext(DateRangeContext);
};
