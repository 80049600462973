import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './EmployerList.module.scss';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import rightAro from '../../assets/images/rightArrow.svg';
import rocketSpeed from '../../assets/images/rocketSpeed.svg';
import notavailableVideo from '../../assets/images/videonotAvailable.svg';
import defaultPro from '../../assets/images/defaultprofile.svg';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import DataTable from '../common/DataTable/EmployerDataTable';
import { getEmployerDetail } from '../../services/api/admin';
import { hideLoader, showLoader } from '../../helpers';
import { formatDistanceToNow } from 'date-fns';
function EmployerDetail() {
  const { id } = useParams();
  const [employerData, setEmployerData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        showLoader();
        let params = {
          id: id,
        };

        let {
          data: { data },
        } = await getEmployerDetail(params);

        // Set the fetched data to the state
        setEmployerData(data);

        hideLoader();
      } catch (error) {
        console.error('Error fetching data:', error);
        hideLoader();
        // Handle the error, e.g., show an error message to the user
      }
    };

    getData();
  }, [id]);

  const formatDistance = (date) => {
    const distance = formatDistanceToNow(date, { addSuffix: true, includeSeconds: true });
    return distance.replace('about ', '').replace('ago', '');
  };
  
  return (
    <HomeLayout employerName={employerData?.employerDetails.fullName} companyName={employerData?.employerDetails.companyName}>
       
      <div className={styles.mainContent}>
        <Container fluid>
          <Row>
            <Col md={12} lg={8} className={styles.tabDashboard}>
              <Row>
                {employerData && (
                  <>
                    <Col md={12} lg={6}>
                      <div className={styles.interviewTab}>
                        <h3>
                          <span>Waiting for an interview</span> {employerData.interview} Candidates
                        </h3>
                        <div className={styles.candidateList}>
                          {/* Display the candidate images here based on the data */}
                        </div>
                        <div className={styles.listIcon}></div>
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className={styles.offletterTab}>
                        <h3>
                          <span>Waiting for offer letter acceptance</span> {employerData.pending} Candidates
                        </h3>
                        <div className={styles.candidateList}>
                          {/* Display the candidate images here based on the data */}
                        </div>
                        <div className={styles.listIcon}></div>
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className={styles.waitactionTab}>
                        <h3>
                          <span>Matched candidate waiting for action</span> {employerData.shortlist} Candidates
                        </h3>
                        <div className={styles.candidateList}>
                          {/* Display the candidate images here based on the data */}
                        </div>
                        <div className={styles.listIcon}></div>
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className={styles.inreviewTab}>
                        <h3>
                          <span>In Review</span> {employerData.rejected} Candidates
                        </h3>
                        <div className={styles.candidateList}>
                          {/* Display the candidate images here based on the data */}
                        </div>
                        <div className={styles.listIcon}></div>
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className={styles.livejobsTab}>
                        <h3>
                          <span>Total live jobs</span> {employerData.live} Jobs
                        </h3>
                        <div className={styles.candidateList}>
                          {/* Display the job images here based on the data */}
                        </div>
                        <div className={styles.listIcon}></div>
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className={styles.hiredTab}>
                        <h3>
                          <span>Total hired till now</span> {employerData.hired} Candidates
                        </h3>
                        <div className={styles.candidateList}>
                          {/* Display the candidate images here based on the data */}
                        </div>
                        <div className={styles.listIcon}></div>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col md={12} lg={4} className={styles.videoStall}>
              <div className={styles.videoData}>
                <div className={styles.videonotAvailable}>
                  <p>We are posting every week, <br />you will see some amazing insight here!</p>
                  <img src={notavailableVideo} alt="" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
             <Col md={4} className={styles.jobsDetails}>
             <div className={styles.interviewSpeed}>
                <div className={styles.boxContent}>
                <h4>Recent Jobs</h4>
                <a href="/joblist/Live" className={styles.viewAllbutton}>
                    View All <img src={rightAro} />
                </a>
                <div className={styles.candidateList}>
                    <ul className={styles.noticeList}>
                    {employerData && employerData.recentJobs.map((job) => (
                        <li key={job.id}>
                        <a href="#">
                            {/* You can customize the content based on the job data */}
                            <span className={styles.name} style={{ color: job.jobStatus === 'Pending' ? '#ff6600' : 'green' }}>
                            {job.position}
                            </span>
                            <span className={styles.desc}>{job.companyNameOnInvoice} - {job.jobCity}</span>
                                <span className={styles.city}>{formatDistance(new Date(job.createdAt))} ago</span>
                            {/* Add more elements based on your API response */}
                        </a>
                        </li>
                    ))}
                    {(!employerData || employerData.recentJobs.length === 0) && (
                        <p className={styles.loadingData}>No recent jobs available.</p>
                    )}
                    </ul>
                </div>
                </div>
              </div>
              </Col>

                <Col md={8} className={styles.jobsDetails}>
                    <div className={styles.interviewSpeed}>
                        <h4>Jobs</h4>
                        {/* <a href="/joblist/Live" className={styles.viewAllbutton}>View All <img src={rightAro} /></a> */}
                        <Row>
                        <Col md={4} lg={4} xl={6}>
                            <div className={styles.smallBox} style={{ borderColor: '#1abc9c' }}>
                            <h3>Live Jobs</h3>
                            <h2 style={{ color: '#1abc9c' }}>{employerData?.live || 0}</h2>
                            </div>
                        </Col>
                        <Col md={4} lg={4} xl={6}>
                            <div className={styles.smallBox} style={{ borderColor: '#3498db' }}>
                            <h3>Pending Jobs</h3>
                            <h2 style={{ color: '#3498db' }}>{employerData?.pendingCreatedJobs || 0}</h2>
                            </div>
                        </Col>
                        <Col md={4} lg={4} xl={6}>
                            <div className={styles.smallBox} style={{ borderColor: '#f1c40f', marginBottom: '0px' }}>
                            <h3>Suspend Jobs</h3>
                            <h2 style={{ color: '#f1c40f' }}>{employerData?.suspendJobs || 0}</h2>
                            </div>
                        </Col>
                        <Col md={4} lg={4} xl={6}>
                            <div className={styles.smallBox} style={{ borderColor: '#e74c3c', marginBottom: '0px' }}>
                            <h3>Closed Jobs</h3>
                            <h2 style={{ color: '#e74c3c' }}>{employerData?.closedJobs || 0}</h2>
                            </div>
                        </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} className={styles.padSide}>
                    <div className={styles.interviewSpeed}>
                        <Row>
                            <Col md={6} lg={6} xl={3}>
                                <div className={styles.rocketspeed}>
                                    <p>Fastest Application</p>
                                    <h3>
                                        <span>{employerData?.candidateAppliedMinHours||0}</span> seconds
                                    </h3>
                                    <img src={rocketSpeed} alt="" />
                                </div>
                            </Col>
                            <Col md={6} lg={6} xl={3}>
                                <div className={styles.rocketspeed}>
                                    <p>Quickest Interview</p>
                                    <h3>
                                        <span>0</span> minutes
                                    </h3>
                                    <img src={rocketSpeed} alt="" />
                                </div>
                            </Col>
                            <Col md={6} lg={6} xl={3}>
                                <div className={styles.rocketspeed}>
                                    <p>Successful Interviews</p>
                                    <h3>
                                        <span>{employerData?.totalInterviewsOccurred}</span> interviews
                                    </h3>
                                    <img src={rocketSpeed} alt="" />
                                </div>
                            </Col>
                            <Col md={6} lg={6} xl={3}>
                                <div className={styles.rocketspeed}>
                                    <p>Average Interview Time</p>
                                    <h3>
                                        <span>0</span> minutes
                                    </h3>
                                    <img src={rocketSpeed} alt="" />
                                 </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </HomeLayout>
  );
}

export default EmployerDetail;
