import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; 
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css'; 
import reportWebVitals from './reportWebVitals';
import { DateRangeProvider } from '../src/components/context/ DateRangeContext'; // Import your DateRangeProvider
<link rel="stylesheet" type="text/css" href="https://cdn.com/bootstrap.css"/>

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DateRangeProvider>
    <App />
    </DateRangeProvider>
  </React.StrictMode>
);

reportWebVitals();
