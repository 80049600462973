import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dummyPhoto from '../../../assets/images/dummyImage.png';
import styles from './DataTable.module.scss';  // Make sure to import your styles
import ReactPaginate from 'react-paginate'; 

function LeadershipTable({ data }) {

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * itemsPerPage;
  const paginatedData = data.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <Sheet>
      <Table aria-label="collapsible table" className={styles.tableBG}>
        <thead style={{ backgroundColor: '#3f51b5', color: 'white' }}>
          <tr>
            <th style={{ width: '20%', background: '#1a2744',color:'#c0ccfc' }}>Rank</th>
            <th style={{ width: '40%', background: '#1a2744',color:'#c0ccfc' }}>Name</th>
            <th style={{ width: '30%', background: '#1a2744',color:'#c0ccfc', textAlign: 'left' }}>City</th>
            <th style={{ width: '10%', background: '#1a2744',color:'#c0ccfc' }} aria-label="empty" />
          </tr>
        </thead>
        <tbody className={styles.bgFold}>
          {paginatedData.map((candidate, index) => (
            <Row key={candidate._id} candidate={candidate} initialOpen={index === 0} rank={index + 1} />
          ))}
        </tbody>
      </Table>
      <ReactPaginate
        pageCount={Math.ceil(data.length / itemsPerPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        activeClassName={styles.active}
      />
    </Sheet>
    
  );
} 

function Row({ candidate, initialOpen,rank }) {
  const [open, setOpen] = React.useState(initialOpen || false);
  const rankWithSuffix = () => {
    const lastDigit = rank % 10;
    const suffix = (lastDigit === 1 && rank !== 11) ? 'st' : (lastDigit === 2 && rank !== 12) ? 'nd' : (lastDigit === 3 && rank !== 13) ? 'rd' : 'th';
    return `${rank}${suffix}`;
  };
  return (
    <React.Fragment>
      <tr className={styles.bgColor} aria-label="expand row" variant="plain" color="neutral" size="sm" onClick={() => setOpen(!open)}>
        <td scope="row">
          <div className={styles.randDetail}>
          <h1>{rankWithSuffix()}</h1>
          </div>
        </td>
        <td scope="row">
          <div className={styles.userDetail}>
            
          <h3>
              {candidate.candidateProfileImage ? (
                <img src={candidate.candidateProfileImage} alt="" />
              ) : (
                <img src={dummyPhoto} alt="Dummy" />
              )}
              {candidate.candidateName}
            </h3>
          </div>
        </td>
        <td scope="row">{candidate.city}</td>
        <td>
          <IconButton className={styles.hoverButton} style={{ color: '#ffffff' }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0 }} colSpan={6}>
          {open && (
            <div
              variant="soft"
              className={styles.listQuestion}
              sx={{ p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)' }}
            >
              <div className={styles.quetionList}>
                <h3>Skill Score</h3>
                <ul className={styles.listStyle}>
                  {candidate.challenges.map((challenge) => (
                    <li key={challenge.createdAt}>
                      <div className={styles.listQuestionName}>
                      <h4>{challenge.averageScore % 1 !== 0 ? challenge.averageScore.toFixed(2) : challenge.averageScore.toFixed(0)}%</h4>
                        <h2>{challenge.challengeType}</h2>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </td>
      </tr>
    </React.Fragment>
    
  );
}

LeadershipTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LeadershipTable;
