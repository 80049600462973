// import { getValue } from '@testing-library/user-event/dist/utils';
import Select from 'react-select';


export const Dropdown=({
    name,
    isDisabled,
    isLoading,
    isSearchable = false,
    isClearable,
    options,
    isMultiple,
    defaultValue,
    isRtl,
    borderRadius,
    placeholder,
    
    onChange = ()=> {
        return null
    },
    className,
    value,
    error,
    inputRef,
    isOptionDisabled,
    inputProps,
    ref,
    // maxOptions,
    isLightTheme
})=>{
      const customStyles = {
        groupHeading: (provided, { selectProps: { isLightTheme }}) => ({
          ...provided,
          color: "#E4E6EA",
          padding: 10,
          background: isLightTheme ? "#474a4dcf" : "#121931",
          fontSize: "13px"
        }),
        control: (provided /** ,{isFocused, isSelected}*/)=>({
            ...provided,
            borderRadius,
            background: "none",
            border: "none",
            boxShadow: "none"
        }),
        placeholder: (provided /** ,state*/)=>({
            ...provided,
            textAlign: "left",
            color: "lightGrey"
        }),
        singleValue: (provided, {isDisabled, selectProps: { isLightTheme }})=>({
            ...provided,
            textAlign: "left",
            marginLeft: "10px",
            background: "none",
            color: isLightTheme ? "#626669" : "#E4E6EA",
            opacity: isDisabled ? 0.5 : 1,
            transition: 'opacity 300ms'
        }),
        input:  (provided, { selectProps: { isLightTheme }})=>({
            ...provided,
            textAlign: "left",
            background: "none",
            color: isLightTheme ? "#626669" : "#C0CCFC"
        }),
        option:  (provided, {isFocused, isSelected, isDisabled, selectProps: { isLightTheme }})=>({
            ...provided,
            textAlign: "left",
            backgroundColor: isFocused && isLightTheme ? "#f3f3f3" : isFocused && !isLightTheme ? "#31426e" : isSelected && isLightTheme ? "#5f5f61a3" : isSelected && !isLightTheme ? "#273457" : isDisabled ? "#525e7e" : "none"
        }),
        menu:  (provided, {selectProps: { isLightTheme }})=>({
            ...provided,
            textAlign: "left",
            background:  isLightTheme ? "#cdcaca" : "#1B2744",
            color: isLightTheme ? "#626669" : "#C0CCFC",
            zIndex: 9999
        }),
        menuList: (provided /** ,state*/)=>({
            ...provided,
            maxHeight: '150px'
        }),
        inputContainer: (provided/** , state*/)=>({
            ...provided,
            textAlign: "left",
            color: "#C0CCFC"
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        }),
        multiValue: (styles/** ,{ data }*/) => {
            return {
              ...styles,
           background: "none",
            color: "#E4E6EA",
            opacity: isDisabled ? 0.5 : 1,
            transition: 'opacity 300ms',
            marginRight: '5px'
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#E4E6EA',
            backgroundColor: '#1b2744',
            border: "1px solid #ff6600",
            padding: "3px",
            borderRadius: "4px 0px 0px 4px",
            borderRight: 'none',
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#E4E6EA',
            backgroundColor: '#1b2744',
            border: "1px solid #ff6600",
            borderLeft: 'none',
            borderRadius: "0px 4px 4px 0px",
            ':hover': {
              backgroundColor: '#273457'
            },
          }),
        
      }
      
    return (
        <div>
            {isMultiple ? (
                <Select
                defaultValue={defaultValue}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable && options ? true : false}
                name={name}
                options={options}
                // options={value && maxOptions && value.length === maxOptions ? [] : options}
                // noOptionsMessage={() => {
                //     return value && value.length === maxOptions ? "You've reached the max options value" : 'No options available' ;
                //   }}
                isMulti
                className={className}
                onChange={onChange}
                placeholder={placeholder}
                styles={customStyles}
                value={value}      
                ref={inputRef}
                isOptionDisabled={isOptionDisabled}   
                inputProps={inputProps}
                   
            />
            ) : (
                <Select
                className={`${className} "basic-single"`}
                classNamePrefix="select"
                defaultValue={defaultValue}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable && options ? true : false}
                name={name}
                options={options}
                // options={value && value.length === maxOptions ? [] : options}
                // noOptionsMessage={() => {
                //     return value && value.length === maxOptions ? "You've reached the max options value" : 'No options available' ;
                //   }}
                styles={customStyles}
                onChange={onChange}
                // onInputChange={getData}
                placeholder={placeholder}
                ref={inputRef}
                value={value}
                isOptionDisabled={isOptionDisabled}
                inputProps={inputProps}
                isLightTheme={isLightTheme}
            />
            )}
            {error !== undefined && error.type === 'required' && (
                <span className="errorMessage">Required</span>
            )}
        </div>
    )
}