// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JobList_mainContent__A0YkX {
  position: relative;
  z-index: 10;
  margin-left: 25px;
  margin-right: 20px;
  padding-top: 60px;
}
.JobList_mainContent__A0YkX .JobList_dataListEmp__9oaZD {
  background-color: #1B2744;
  border-radius: 10px;
  position: relative;
  padding: 20px;
  margin: 0px 0px 20px 0px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
}

.JobList_loadingData__ePP4\\+ {
  font-size: 14px;
  letter-spacing: 0.03em;
  color: #fff;
}

.JobList_erroeMessage__0miX6 {
  font-size: 14px;
  letter-spacing: 0.03em;
  color: #ff6600;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .JobList_mainContent__A0YkX {
    margin: 52px 0px 0px 0px;
  }
  .JobList_mainContent__A0YkX .JobList_dataListEmp__9oaZD {
    padding: 20px 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/JobList/JobList.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAAI;EACI,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,wBAAA;EACA,wEAAA;EACA,6EAAA;EACA,gFAAA;AAER;;AACA;EACI,eAAA;EACA,sBAAA;EACA,WAAA;AAEJ;;AAAA;EACI,eAAA;EACA,sBAAA;EACA,cAAA;AAGJ;;AAAA;EACI;IACI,wBAAA;EAGN;EAFM;IACI,kBAAA;EAIV;AACF","sourcesContent":[".mainContent {\n    position: relative;\n    z-index: 10;\n    margin-left:25px;\n    margin-right: 20px;\n    padding-top: 60px;\n    .dataListEmp{\n        background-color: #1B2744;\n        border-radius: 10px;\n        position: relative;\n        padding: 20px;\n        margin: 0px 0px 20px 0px;\n        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);\n        -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);\n        -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);\n    }\n}\n.loadingData{\n    font-size: 14px;\n    letter-spacing: 0.03em;\n    color: #fff;\n}\n.erroeMessage{\n    font-size: 14px;\n    letter-spacing: 0.03em;\n    color: #ff6600;\n}\n\n@media only screen and (min-width: 320px) and (max-width: 479px) {\n    .mainContent {\n        margin:52px 0px 0px 0px;\n        .dataListEmp{\n            padding:20px 10px;\n        }\n    }\n}\n@media only screen and (min-width: 769px) and (max-width: 1024px) { \n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": `JobList_mainContent__A0YkX`,
	"dataListEmp": `JobList_dataListEmp__9oaZD`,
	"loadingData": `JobList_loadingData__ePP4+`,
	"erroeMessage": `JobList_erroeMessage__0miX6`
};
export default ___CSS_LOADER_EXPORT___;
