import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";
export const loginRequest = (data) => {
  return axiosInstance({ method: "post", url: endPoints.admin.login, data });
};
export const getDashboard = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.admin.getDashboard, params });
};

export const getemployerList = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.admin.getemployerList, params });
};
export const getcandidateList = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.admin.getcandidateList, params });
};
export const getJobList = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.admin.getJobList, params });
};
export const getJobMatchList = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.admin.getJobMatchList, params });
};
export const searchByUid = (data) => {
  return axiosInstance({ method: "post", url: endPoints.admin.searchByUid, data });
};
export const verifyEmployer = (data) => {
  return axiosInstance({ method: "put", url: endPoints.admin.verifyEmployer, data });
};
export const challengeScores = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.admin.challengeScores, params });
};
export const getEmployerDetail = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.admin.getEmployerDetail, params });
};