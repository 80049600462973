const baseURL = "https://api.jobsgaar.com/v1";
const adminURL = `${baseURL}/admin`;
const commonURL = `${baseURL}/common`;
const employerURL = `${baseURL}/employer`;
export const endPoints = {
    admin: {
        login: `${adminURL}/login`,
        getDashboard: `${adminURL}/viewDashboard/`,
        getemployerList: `${adminURL}/employerList/`,
        getcandidateList: `${adminURL}/candidateList/`,
        getJobList:`${adminURL}/jobs/{status}`,
        getJobMatchList:`${adminURL}/jobmatch/{jobId}`,
        searchByUid:`${adminURL}/searchUid`,
        verifyEmployer:`${adminURL}/verifyEmployer`,
        challengeScores:`${adminURL}/challengeScores`,
        getEmployerDetail:`${adminURL}/employerDetail/{employerid}`,
    },
    common: {
        cityListByState: `${commonURL}/get/statewise/city/list`,
        getExperienceList: `${commonURL}/get/experience/list`,
        getEducationList: `${commonURL}/get/education/list`,
        getPositionList: `${commonURL}/get/position/list`
    },
    employer: {
        getDashboard: `${employerURL}/dashboard/{id}`
    },
    
}