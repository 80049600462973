import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Year", "Sales", "Expenses"],
  ["2004", 1000, 400],
  ["2005", 1170, 460],
  ["2006", 660, 1120],
  ["2007", 12030, 2540],
];

export const options = {
  legend: { position: "none" },
    backgroundColor: '#1B2744',
    legendTextStyle: { color: '#FFF' },
    titleTextStyle: { color: '#FFF' },

    hAxis: {
      // title: 'Time',
      textStyle: {
        color: '#fff',
        fontSize: 12,
        fontName: 'Arial',
        bold: true,
        italic: true
      },
      titleTextStyle: {
        color: '#fff',
        fontSize: 12,
        fontName: 'Arial',
        bold: false,
        italic: true
      }
    },
    vAxis: {
      // title: 'Popularity',
      textStyle: {
        color: '#fff',
        fontSize: 12,
        bold: true
      },
      titleTextStyle: {
        color: '#fff',
        fontSize: 12,
        bold: true
      }
    },
    series: {
      // 1: { curveType: "function" },
      0: { color: '#ff6600' }, 
      1: { color: 'transparent' },  
    },
    
  };

  export function ReactChartLine({ data }) {
    // Add "Weekly" as the label for the x-axis
    data[0][0] = "Weekly";
  
    return (
      <div>
        <Chart
          chartType="LineChart"
          width="100%"
          height="183px"
          data={data}
          options={options}
        />
      </div>
    );
  }

export default ReactChartLine;



