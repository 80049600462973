import React, { useState } from 'react';
import Dashboard from "../../components/Dashboard/Dashboard";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import SideBarCommon from '../../components/common/SidebarCommon/SideBarCommon';

//css
import styles from './HomeLayout.module.scss';

const HomeLayout = ({ children,employerName,companyName }) => {
const [isSidebarOpen, setIsSidebarOpen] = useState(true);
const [isLoading, setIsLoading] = useState(false);
const toggleSidebar = () => {
  
        setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
      };
     
    return (
        <>
       <div className={`${styles.wrapper} ${isSidebarOpen ? '' : styles['sidebar-closed']}`}>
          <Header
            employerName={employerName}
            companyName={companyName}
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
          />
          {isSidebarOpen && <Sidebar />}
          <div className={styles.content}>
            {children}
          </div>
        </div>
        <Footer />
      </>
    )
}

export default HomeLayout;