import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import styles from './SidebarCommon.module.scss'
import DateRangePicker from '../DatePicker/DatePicker';
import Dashboard from '../../Dashboard/Dashboard'
// import './index.css';

// const SidebarCommon = ({ children }) => {
function SidebarCommon() {

  const [showNav, setShowNav] = useState(true)

  return <div className={`body-area${showNav ? ' body-pd' : ''}`}>
    <header className={`header${showNav ? ' body-pd' : ''}`}>
      <div className="header_toggle">
        <i
          className={`bi ${showNav ? 'bi-x' : 'bi-list'}`}
          onClick={() => setShowNav(!showNav)} />
      </div>
      {/* <div className="header_img">
        <img
          src="https://reqres.in/img/faces/5-image.jpg"
          alt="Clue Mediator" />
      </div> */}
      <div className={styles.icoItem}>
            <DateRangePicker />
        </div>
    </header>
    <div className={`l-navbar${showNav ? ' show' : ''}`}>
      <nav className="nav">
        <div>
          <a href="" target="_blank" className="nav_logo" rel="noopener">
            <i className='bi bi-alexa nav_logo-icon' /> <span className="nav_logo-name">Clue Mediator</span>
          </a>
          <div className="nav_list">
            <a href="" target="_blank" className="nav_link" rel="noopener">
              <i className='bi bi-people nav_icon' /><span className="nav_name">Users</span>
            </a>
            <a href="" target="_blank" className="nav_link" rel="noopener">
              <i className='bi bi-person-check nav_icon' /><span className="nav_name">Role</span>
            </a>
          </div>
        </div>
        <a href="" target="_blank" className="nav_link" rel="noopener">
          <i className='bi bi-box-arrow-left nav_icon' /><span className="nav_name">SignOut</span>
        </a>
      </nav>
    </div>
    <div className={styles.fistCon}>
       <Dashboard/>
    </div>
  </div>
}

export default SidebarCommon;