import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import styles from './DataTable.module.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown} from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { CustomerService } from '../../service/JobServiceList';
import '../../../../src/flags.css';
import { Link } from 'react-router-dom';
export default function AdvancedFilterDemo({ data }) {
    const [customers, setCustomers] = useState(data);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    

    useEffect(() => {
      
        initFilters();
    }, []);

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            const employerActionHistory = d.employerActionHistory || {};
            d.jobPosted = new Date(d.jobPosted);
            d.lastActionDatetime = employerActionHistory.dateTime ? new Date(employerActionHistory.dateTime) : null;
            d.lastAction = employerActionHistory.status;
            return d;
        });
    };
    
    
    

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            city: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            whatsappMobile: { value: null, matchMode: FilterMatchMode.IN },
            jobPosted: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            campaignSourceURL: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            companyName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            companyLogo: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            verifiedEmployer: { value: null, matchMode: FilterMatchMode.EQUALS },
            lastAction: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            lastActionDatetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            source: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="btlFilterClr justify-content-between">
                  <Button className={styles.btnCler} type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText className={styles.formCon} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                {/* <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} /> */}
                <span>{rowData.jobCity}</span>
            </div>
        );
    };

    const detailsBodyTemplate = (rowData) => {
        // Extract jobId from the _id field
        const jobId = rowData._id ? rowData._id.jobId : null;
    
        // Define the route with the jobId parameter
        const route = `/details/${jobId}`;
       
    
        return (
            <Link to={route}>
                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-text" tooltip="Details" tooltipOptions={{ position: 'bottom' }} />
            </Link>
        );
    };
    
    

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} severity="secondary"></Button>;
    };

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
    };

    const filterFooterTemplate = () => {
        return <div className="px-3 pt-0 pb-3 text-center">Filter by Country</div>;
    };


    const dateBodyTemplate = (rowData) => {
        if (rowData.jobPosted) {
            const date = new Date(rowData.jobPosted);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return '';
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const dateTimeBodyTemplate = (rowData) => {
        if (rowData && rowData.employerActionHistory && rowData.employerActionHistory.dateTime) {
            const date = new Date(rowData.employerActionHistory.dateTime);
            return date.toLocaleString(); // Adjust the formatting based on your requirements
        }
        return '';
    };
    
    const dateTimeFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} showTime dateFormat="mm/dd/yy HH:mm" placeholder="mm/dd/yyyy HH:mm" />;
    };
        

    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={customers} paginator showGridlines rows={20} loading={loading} dataKey="id"
                    filters={filters} globalFilterFields={['fullName', 'jobCity','alternateNo', 'whatsappMobile','designation', 'TotalApplied','shortlisted','interview','rejectedByEmployer','rejectedByCandidate','totalMatchedCandidates','pendingFromCandidate','pendingFromEmployer', 'companyName']} header={header}
                    emptyMessage="No records found.">
                <Column body={detailsBodyTemplate} style={{ textAlign: 'center', width: '3rem' }}/>
                <Column field="fullName" header="Employer Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                <Column field="whatsappMobile" header="Number" filter filterPlaceholder="Search by number" style={{ minWidth: '12rem' }} />
                <Column field="companyName" header="Company Name" filter filterPlaceholder="Search by company name" style={{ minWidth: '12rem' }} />
                <Column field="designation" header="Job Position" filter filterPlaceholder="Search by Designation" style={{ minWidth: '12rem' }} />
                <Column header="Posted On" filterField="createdAt" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column header="Job City" filterField="jobCity" style={{ minWidth: '12rem' }} body={countryBodyTemplate}
                    filter filterPlaceholder="Search by city"  filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate} filterFooter={filterFooterTemplate} />
                <Column field="candidatesCount" header="Tentative Matches" filter filterPlaceholder="Search by" style={{ minWidth: '12rem' }} />
                <Column field="totalMatchedCandidates" header="Matched" filter filterPlaceholder="Search by " style={{ minWidth: '12rem' }} />
                <Column field="TotalApplied" header="Applied" filter filterPlaceholder="Search by " style={{ minWidth: '12rem' }} />
                <Column field="rejectedByCandidate" header="Rejected by candidate" filter filterPlaceholder="Search by " style={{ minWidth: '12rem' }} />
                <Column field="shortlisted" header="Shortlisted" filter filterPlaceholder="Search by company name" style={{ minWidth: '12rem' }} />
                <Column field="interview" header="Interview" filter filterPlaceholder="Search by " style={{ minWidth: '12rem' }} />
                <Column field="rejectedByEmployer" header="Rejected By Employer" filter filterPlaceholder="Search by" style={{ minWidth: '12rem' }} />
                <Column field="employerActionHistory.status" header="Last Action" filter filterPlaceholder="Search by Last Action" style={{ minWidth: '12rem' }} />
                <Column field="lastActionDatetime" header="Last Action Datetime" filterField="lastActionDatetime" dataType="date" style={{ minWidth: '12rem' }} body={dateTimeBodyTemplate} filter filterElement={dateTimeFilterTemplate} />
                <Column field="employerActionHistory.source" header="source" filter filterPlaceholder="Search by Last Action" style={{ minWidth: '12rem' }} />


            </DataTable>
        </div>
    );
}
