import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './UserSearch.module.scss';
import { useForm } from 'react-hook-form';
import { Dropdown } from '../../components/common/DropDown/Dropdown';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import DataTable from '../common/DataTable/SearchUidTable'; // Assuming DataTable is a PrimeReact component
import { getCity, getEducationList, getExperienceList, getPositionList } from '../../services/api/common';
import { searchByUid } from '../../services/api/admin';

function UserSearch({
  isOpen,
  handleClose,
  jobPositionData = [],
  cityData = [],
  handleJobFilter,
  path,
}) {
  const { setValue } = useForm();

  const [positionValue, setPositionValue] = useState([]);
  const [cityValue, setCityValue] = useState([]);
  const [experience, setExperienceData] = useState([]);
  const [education, setEducationData] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [tabledata, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const jobPositionDropdownRef = useRef(null);
  const cityDropdownRef = useRef(null);
  const experienceDropdownRef = useRef(null);
  const educationDropdownRef = useRef(null);
  const [dropdownKey, setDropdownKey] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [selectedGender, setSelectedGender] = useState(null);
  useEffect(() => {
    getCityList();
    getExperience();
    getEducation();
    getJobPosition();
  }, []);
  
  const getCityList = async () => {
    try {
      const {
        data: { data },
      } = await getCity();
      setCityValue(
        data &&
          data.length > 0 &&
          data.map(({ stateName, districtName }) => {
            let data = districtName.map((item) => ({ label: item, value: item, state: stateName }));
            return {
              label: stateName,
              options: data,
            };
          })
      );
    } catch (error) {}
  };

  const getExperience = async () => {
    try {
      const {
        data: { data },
      } = await getExperienceList();
      setExperienceData(
        data &&
          data.length > 0 &&
          data.map(({ experience, experienceYears }) => ({
            label: experience,
            value: experienceYears,
          }))
      );
    } catch (error) {}
  };

  const getEducation = async () => {
    try {
      const {
        data: { data },
      } = await getEducationList();
      let educationContent =
        data &&
        data.length > 0 &&
        data.map(({ education, educationSort }) => ({
          label: education,
          value: educationSort,
        }));
      educationContent = educationContent.reduce((unique, o) => {
        if (!unique.some((obj) => obj.label === o.label)) {
          unique.push(o);
        }
        return unique;
      }, []);

      setEducationData(educationContent);
    } catch (error) {}
  };

  const getJobPosition = async () => {
    try {
      const {
        data: { data },
      } = await getPositionList();
      setPositionValue(
        data &&
          data.length > 0 &&
          data.map(({ position, positionId, positionUid }) => ({
            label: position,
            value: positionId,
            positionUid,
          }))
      );
    } catch (error) {}
  };

 
  
  const handleGenderSelection = (gender) => {
    setSelectedGender((prevGender) => {
      // Toggle the gender selection
      if (prevGender === gender) {
        return null; // Deselect if already selected
      } else {
        return gender; // Select if not already selected
      }
    });
  };

  const handleJobPosition = ({ label, value, positionUid }) => {
    setSelectedJobPosition({ label, value, positionUid });
    setValue('jobPosition', label);
    setValue('jobPositionId', value);
    setValue('positionUid', positionUid);
  };

  const handleCity = (city, state) => {
    setSelectedCity({ city });
  };

  const handleExperience = ({ label, value }) => {
    setSelectedExperience({ value });
  };

  const handleEducation = ({ label, value }) => {
    setSelectedEducation({ value });
  };

  const handleApply = async () => {
    try {
      setLoading(true);
  
      const params = {
        designationId: selectedJobPosition?.value, // Assuming 'value' represents designationId
        city: selectedCity?.city,
        yearsOfExperience: selectedExperience?.value,
        candidateEducationSort: selectedEducation?.value,
        ...(selectedGender && { gender: selectedGender }),
      };
  
      // Check if the "Include UID" checkbox is checked
      const includeUidCheckbox = document.getElementById('includeUidCheckbox');
      const includeUid = includeUidCheckbox.checked;
  
      if (includeUid) {
        params.designationUid = selectedJobPosition?.positionUid;
      }
  
      const { data: { data } } = await searchByUid(params);
  
      setAppliedFilters(true);
      setResultCount(data.length);
      setTableData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleReset = () => {
    setSelectedJobPosition([]);
    setSelectedCity([]);
    setSelectedExperience([]);
    setSelectedEducation([]);
    setAppliedFilters(false);
    setTableData([]);
    setDropdownKey((prevKey) => prevKey + 1);
    setResultCount([])
    setSelectedGender(null); 
    // Update selected values in state
    if (jobPositionDropdownRef.current) {
      jobPositionDropdownRef.current.clear(); // assuming the 'clear' method exists on your Dropdown component
    }
    if (cityDropdownRef.current) {
      cityDropdownRef.current.clear();
    }
    if (experienceDropdownRef.current) {
      experienceDropdownRef.current.clear();
    }
    if (educationDropdownRef.current) {
      educationDropdownRef.current.clear();
    }
    const includeUidCheckbox = document.getElementById('includeUidCheckbox');
    if (includeUidCheckbox) {
        includeUidCheckbox.checked = false;
    }
  };
  return (
    <HomeLayout>
      <div className={styles.mainContent}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.dataListEmp}>
                 <Row>
                  <Col md={3} className={styles.FormGroup}>
                    <label>Job Position</label>
                    <Dropdown
                      name="jobPosition"
                      isSearchable={true}
                      options={positionValue}
                      key={dropdownKey}
                      ref={jobPositionDropdownRef}
                      placeholder="Select the Job Position"
                      className={styles.formControlselect}
                      onChange={(e) => handleJobPosition(e)}
                    />
                  </Col>
                  <Col md={3} className={styles.FormGroup}>
                    <label>City</label>
                    <Dropdown
                      name="city"
                      isSearchable={true}
                      options={cityValue}
                      key={dropdownKey}
                      placeholder="Select City"
                      ref={cityDropdownRef}
                      className={styles.formControlselect}
                      onChange={(e) => handleCity(e.value, e.state)}
                    />
                  </Col>
                  <Col md={3} className={styles.FormGroup}>
                    <label>Experience</label>
                    <Dropdown
                      name="experience"
                      isSearchable={true}
                      options={experience}
                      ref={experienceDropdownRef}
                      key={dropdownKey}
                      placeholder="Select experience"
                      className={styles.formControlselect}
                      onChange={(e) => handleExperience(e)}
                    />
                  </Col>
                  <Col md={3} className={styles.FormGroup}>
                    <label>Education</label>
                    <Dropdown
                      name="education"
                      isSearchable={true}
                      options={education}
                      ref={educationDropdownRef}
                      key={dropdownKey}
                      placeholder="Select the education"
                      className={styles.formControlselect}
                      onChange={(e) => handleEducation(e)}
                    />
                  </Col>
                  <Col md={2}>
                    <div className={styles.newSelect}>
                      <ul>
                        <li>
                          <label className={styles.checkbox}>
                            <input
                              type="checkbox"
                              className={styles.checkboxInput}
                              checked={selectedGender === "Male"}
                              onChange={() => handleGenderSelection("Male")}
                            />
                            <span className={styles.checkboxInner} />
                          </label>
                          <label htmlFor="ownCar">Male</label>
                        </li>
                        <li>
                          <label className={styles.checkbox}>
                            <input
                              type="checkbox"
                              className={styles.checkboxInput}
                              checked={selectedGender === "Female"}
                              onChange={() => handleGenderSelection("Female")}
                            />
                            <span className={styles.checkboxInner} />
                          </label>
                          <label htmlFor="ownCar">Female</label>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col md={10}>
                    <Row>
                      <Col md={6}>
                        <div className={styles.inclUID}>
                            <div className={styles.includeUID}>
                              <label className={styles.checkbox}>
                              <input
                                    id="includeUidCheckbox"
                                    className={styles.checkboxInput}
                                    type="checkbox"
                                    />
                                <span className={styles.checkboxInner} />
                              </label>
                              <label className={styles.inHead}>Include UID</label>
                            </div>
                        </div>
                      </Col>
                      <Col md={6}>
                      <div className={styles.btnNew}>
                        <div className={styles.btnCancel}>
                          <div className={styles.effNew}></div>
                          <button onClick={handleReset}>Reset </button>
                        </div>
                        <div className={styles.btnReset}>
                          <div className={styles.effReset}></div>
                          <button onClick={handleApply}>Apply</button>
                        </div>
                      </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className={styles.totalRes}>
                  <Col md={12}>
                      {appliedFilters && (
                      <p style={{ color: 'white' }}>Total Results: {resultCount}</p>
                      )}
                  </Col>
              </Row>
          
                {appliedFilters && (
                  <Row>
                    <Col md={12}>
                      {loading ? (
                        <p className={styles.loadingData}>Loading data...</p>
                      ) : error ? (
                        <p className={styles.erroeMessage}>Error: {error.message}</p>
                      ) : (
                        <DataTable data={tabledata} />
                      )}
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </HomeLayout>
  );
}

export default UserSearch;
