import React from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import styles from './Footer.module.scss'; 
import companyLogo  from '../../../assets/images/logo.svg';
import { Icon } from '@fortawesome/fontawesome-svg-core';

function Footer() {


  return (
    <div class={styles.mainMenu}>
	
</div>
  );
}

export default Footer;
