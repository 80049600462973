import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './LeaderShipBoard.module.scss';
import { useForm } from 'react-hook-form';
import { Dropdown } from '../../components/common/DropDown/Dropdown';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import DataTable from '../common/DataTable/LeaderShipTable'; // Assuming DataTable is a PrimeReact component
import { getCity, getEducationList, getExperienceList, getPositionList } from '../../services/api/common';
import { challengeScores } from '../../services/api/admin';

function LeaderShipBoard({
  isOpen,
  handleClose,
  jobPositionData = [],
  cityData = [],
  handleJobFilter,
  path,
}) {
  const { setValue } = useForm();

  const [CategoryTypeValue, setCategoryTypeValue] = useState([

{    label: "IQ Tests",
    value: "IQ Tests"
}, {
    label: "Mathematical Skills",
    value: "Mathematical Skills"
}, {
    label: "Visual Pursuit",
    value: "Visual Pursuit"
}, {
    label: "Motorical Skills",
    value: "Motorical Skills"
}, {
    label: "English Skills",
    value: "English Skills"
}, {
    label: "Consistency Score",
    value: "Consistency Score"
}, {
    label: "General Awareness",
    value: "General Awareness"
}, {
    label: "Tech Abilities",
    value: "Tech Abilities"
}, {
    label: "Emotional Questions",
    value: "Emotional Questions"
},  {
    label: "Logical Reasoning",
    value: "Logical Reasoning"
}, {
    label: "Riding Ability Test",
    value: "Riding Ability Test"
}, {
    label: "Digital Markting",
    value: "Digital Markting"
}, {
    label: "Cook/Chef",
    value: "Cook/Chef"
}]);
  const [cityValue, setCityValue] = useState([]);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [tabledata, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const categoryTypeDropdownRef = useRef(null);
  const cityDropdownRef = useRef(null);
  const [dropdownKey, setDropdownKey] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  useEffect(() => {
    // Call the challengeScores API directly when the page loads
    const fetchData = async () => {
      try {
        setLoading(true);

        const params = {
          challengeType: selectedCategoryType?.value,
          city: selectedCity?.city,
        };

        const { data: { data } } = await challengeScores(params);

        setAppliedFilters(true);
        setResultCount(data.length);
        setTableData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts

    getCityList(); // Load city list

  }, []);
  
  const getCityList = async () => {
    try {
      const {
        data: { data },
      } = await getCity();
      setCityValue(
        data &&
          data.length > 0 &&
          data.map(({ stateName, districtName }) => {
            let data = districtName.map((item) => ({ label: item, value: item, state: stateName }));
            return {
              label: stateName,
              options: data,
            };
          })
      );
    } catch (error) {}
  };


  const handlecategoryType = ({ label, value }) => {
    setSelectedCategoryType({ label, value });
    setValue('categoryType', value);
  };

  const handleCity = (city, state) => {
    setSelectedCity({ city });
  };


 

  const handleApply = async () => {
    try {
      setLoading(true);
  
      const params = {
        challengeType: selectedCategoryType?.value, // Assuming 'value' represents designationId
        city: selectedCity?.city,
      };
  
      const { data: { data } } = await challengeScores(params);
  
      setAppliedFilters(true);
      setResultCount(data.length);
      setTableData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleReset = () => {
    setSelectedCategoryType([]);
    setSelectedCity([]);
    setAppliedFilters(false);
    setTableData([]);
    setDropdownKey((prevKey) => prevKey + 1);
    setResultCount([])
  
    // Update selected values in state
    if (categoryTypeDropdownRef.current) {
      categoryTypeDropdownRef.current.clear(); // assuming the 'clear' method exists on your Dropdown component
    }
    if (cityDropdownRef.current) {
      cityDropdownRef.current.clear();
    }
   
  };
  console.log(tabledata)
  return (
    <HomeLayout>
      <div className={styles.mainContent}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.dataListEmp}>
                 <Row>
                  <Col md={4} className={styles.FormGroup}>
                    <label>By Category</label>
                    <Dropdown
                      name="jobPosition"
                      isSearchable={true}
                      options={CategoryTypeValue}
                      key={dropdownKey}
                      ref={categoryTypeDropdownRef}
                      placeholder="Select category"
                      className={styles.formControlselect}
                      onChange={(e) => handlecategoryType(e)}
                    />
                  </Col>
                  <Col md={4} className={styles.FormGroup}>
                    <label>By City</label>
                    <Dropdown
                      name="city"
                      isSearchable={true}
                      options={cityValue}
                      key={dropdownKey}
                      placeholder="Select City"
                      ref={cityDropdownRef}
                      className={styles.formControlselect}
                      onChange={(e) => handleCity(e.value, e.state)}
                    />
                  </Col>
                 
                  <Col md={4} className={styles.FormGroup}>
                  <div className={styles.btnNew}>
                        <div className={styles.btnCancel}>
                          <div className={styles.effNew}></div>
                          <button onClick={handleReset}>Reset </button>
                        </div>
                        <div className={styles.btnReset}>
                          <div className={styles.effReset}></div>
                          <button onClick={handleApply}>Apply</button>
                        </div>
                      </div>
                  </Col>
                </Row>
                  <Row>
                  <Col md={12}>
                      {loading ? (
                        <p className={styles.loadingData}>Loading data...</p>
                      ) : error ? (
                        <p className={styles.erroeMessage}>Error: {error.message}</p>
                      ) : (
                        <DataTable data={tabledata} />
                      )}
                    </Col>
                  </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </HomeLayout>
  );
}

export default LeaderShipBoard;