import checkMarkImage from '../../assets/images/check-mark.svg';
import { getcandidateList } from '../../services/api/admin';

export const CustomerService = {
  async getData() {
    try {
      const response = await getcandidateList();
      const { data: { data } } = response;
      console.log(data.length); 
      return data;
    } catch (error) {
      // Handle any errors here, e.g., log them or throw an exception.
      console.error('Error fetching customer data:', error);
      throw error;
    }
  },

//   async getCustomersSmall() {
//     const data = await this.getData();
//     return data.slice(0, 50000);
//   },

//   async getCustomersMedium() {
//     const data = await this.getData();
//     return data.slice(0, 5000);
//   },

  async getCustomersLarge() {
    return this.getData();
   
  }

//   async getCustomersXLarge() {
//     return this.getData();
//   },

  // Other methods as before
  // ...
};


