import React from "react";
import { Chart } from "react-google-charts";
import styles from './ReactChartDounat.module.scss'
import { OpacityRounded } from "@mui/icons-material";

// export const data = [
 
//   ["Male", 11], // Specify color for Female segment
//   ["Female", 7],
//  ];

export const options = {
//   title: "My Daily Activities",
  pieHole: 0.6,
  // is3D: false,
  legend: { position: "none" },
  backgroundColor: 'none',
  legendTextStyle: { color: '#FFF'},
  titleTextStyle: { color: '#FFF'},
  textStyle: {
    color: '#fff',
    opacity:'0'
},
colors: ["#3498DB", "#ff6600"],
pieSliceBorderColor: 'transparent',
tooltip: {}, // Remove the tooltip
enableInteractivity: true, // Remove hover interaction
pieSliceText: 'none', 
};

export function ReactChartDount({ data }) {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="305px"
      data={data}
      options={options}
    />
  );
}
export default ReactChartDount;
