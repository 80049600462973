import React, { useState, useRef, useEffect } from 'react';
import searcBtn from '../../../assets/images/searchIcon.svg';
import menuIcon from '../../../assets/images/mainMenu.svg';
import setting from '../../../assets/images/settingIcon.svg';
import bellNot from '../../../assets/images/bellIcon.svg';
import logOutIcon from '../../../assets/images/logOUt.svg';
import Profile from '../../../assets/images/profile.svg';
import Theme from '../../../assets/images/theme.svg';
import Signout from '../../../assets/images/signout.svg';
import pref from '../../../assets/images/preferences.svg';
import styles from './Header.module.scss';
import DateRangePicker from '../DatePicker/DatePicker';
import { showLoader, hideLoader, setCookie } from '../../../helpers/index';
import {  useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';  // Updated import
const routeToTitleMap = {
  '/dashboard': 'Dashboard',
  '/employers': 'Employers',
  '/joblist/Live': 'Live Job',
  '/joblist/Pending':'Pending Job',
  '/joblist/Suspended':'Suspended Job',
  '/joblist/Closed':'Closed Job',
  '/candidate':'Candidates',
  '/UIDSearch':'Search Candidate By UID',
  '/leaderShipBoard':'Leadership Board',
  '/employerdetail':'Employer Detail'
  // Add more routes and titles as needed
};
const employerDetailRouteRegex = /^\/employerdetail\/[\w\d]+$/;
if (employerDetailRouteRegex.test(window.location.pathname)) {
  routeToTitleMap[window.location.pathname] = 'Employer Detail';
}
function Header({ employerName,companyName,toggleSidebar,isSidebarOpen }) {
  const navigate = useNavigate();  // Use useNavigate to get the navigation function
  const location = useLocation();
  const [isShowGearOptions, setIsShowGearOptions] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const trigger = (type) => (event) => {
    event.preventDefault();
    if (type === 'setting') {
      setIsShowGearOptions(!isShowGearOptions);
      if (isShowNotification) {
        setIsShowNotification(false);
      }
    } else {
      setIsShowNotification(!isShowNotification);
      if (isShowGearOptions) {
        setIsShowGearOptions(false);
      }
    }
  };

  const setRef = useRef(null);
  const notificationRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleSettingsOutside, false);
    document.addEventListener("click", handleNotificationOutside, false);
    setPageTitle(routeToTitleMap[location.pathname] || 'Dashboard');

    return () => {
      document.removeEventListener("click", handleSettingsOutside, false);
      document.removeEventListener("click", handleNotificationOutside, false);
    };
  }, []);

  const handleSettingsOutside = (event) => {
    if (setRef.current && !setRef.current.contains(event.target)) {
      setIsShowGearOptions(false);
    }
  };

  const handleNotificationOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setIsShowNotification(false);
    }
  };
  const handleLogout = () => {
    // Clear authentication token (assuming you are using a cookie for authentication)
    setCookie('token', '', { expires: -1 });

    // Reset user state or perform any other necessary cleanup
    localStorage.removeItem('userData');

    // Redirect the user to the login page
    navigate('/login');
   
  };
  return (
    <div className={`${styles.fixedNavbar} ${isSidebarOpen ? '' : styles['sidebar-closed']}`}>
      <div className={styles.pullLeft}>
      <button
          type="button"
          className={styles.menuMobileButton}
          onClick={() => toggleSidebar()}
        >
          <img src={menuIcon} alt="Menu" />
        </button>
        <h1 className={styles.pageTitle}> {pageTitle === 'Employer Detail' && employerName ? `${employerName} (${companyName})` : companyName ? companyName : pageTitle}</h1> 
      </div>
      <div className={styles.pullRight}>
        <div className={styles.icoItem}>
          <DateRangePicker />
        </div>
        <div className={styles.iconButton}>
          <a className={styles.icoItem} onClick={trigger('setting')} ref={setRef}>
            <div className={styles.dropBox}>
              <img src={setting} alt="Settings" />
            </div>
          </a>
          <a className={styles.icoItem}>
            <div className={styles.dropBox}>
              <img src={bellNot} alt="Notification" />
            </div>
          </a>
          <a className={styles.icoItem} onClick={handleLogout}>
            <div className={styles.dropBox}>
              <img src={logOutIcon} alt="Log Out" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
