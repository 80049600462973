import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './JobList.module.scss';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import DataTable from '../common/DataTable/JobDataTable'; // Assuming DataTable is a PrimeReact component
import { getJobList } from '../../services/api/admin';
import { useParams } from 'react-router-dom';
function JobList() {
  // Mock data for example purposes
  const mockData = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Smith' },
    // Add more data items here
  ];


  // State for holding the data
  const [data, setData] = useState(mockData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { path } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let params = {
          status: path,
        };
        const response = await getJobList(params);
        const responseData = response.data.data;
        // Simulate an API request with setTimeout (replace this with actual data fetching)
        setTimeout(() => {
          setData(responseData);
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error('Error fetching job data:', error);
        setError(error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [path]);
  

  return (
    <HomeLayout>
      <div className={styles.mainContent}>
        <Container>
          <Row>
            {/* Add content for rows as needed */}
          </Row>
          <Row>
            <Col md={12}>
              <div className={styles.dataListEmp}>
                {loading ? (
                  <p className={styles.loadingData}>Loading data...</p>
                ) : error ? (
                  <p className={styles.erroeMessage}>Error: {error.message}</p>
                ) : (
                  <DataTable data={data} />
                )}
              </div>
                
            </Col>
          </Row>
        </Container>
      </div>
    </HomeLayout>
  );
}

export default JobList;
