import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './EmployerList.module.scss';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import DataTable from '../common/DataTable/EmployerDataTable'; // Assuming DataTable is a PrimeReact component

function EmployerList() {
  // Mock data for example purposes
  const mockData = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Smith' },
    // Add more data items here
  ];


  // State for holding the data
  const [data, setData] = useState(mockData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // You can fetch data from an API or service here and update the state
    // Example of mock data fetching
    setLoading(true);

    // Simulate an API request with setTimeout
    setTimeout(() => {
      setData(mockData);
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <HomeLayout>
      <div className={styles.mainContent}>
        <Container>
          <Row>
            {/* Add content for rows as needed */}
          </Row>
          <Row>
            <Col md={12}>
              <div className={styles.dataListEmp}>
                {loading ? (
                  <p className={styles.loadingData}>Loading data...</p>
                ) : error ? (
                  <p className={styles.erroeMessage}>Error: {error.message}</p>
                ) : (
                  <DataTable data={data} />
                )}
              </div>
                
            </Col>
          </Row>
        </Container>
      </div>
    </HomeLayout>
  );
}

export default EmployerList;
