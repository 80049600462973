import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { write } from 'xlsx';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import styles from './DataTable.module.scss';
export default function AdvancedFilterDemo({ data }) {
  const [customers, setCustomers] = useState(data);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  useEffect(() => {
    initFilters();
  }, []);
  const updateCustomers = (newData) => {
    setCustomers(newData, () => {
      // Callback to ensure that state has been updated before exporting
      exportToExcel();
    });
  };

  useEffect(() => {
    // Update the customers state when the data prop changes
    updateCustomers(data);
  }, [data]);

  const clearFilter = () => {
    initFilters();
  };

  const exportToExcel = () => {
    const headers = [
      'fullName',
      'whatsappMobile',
      'aadhaarMobile',
      'createdAt',
      'desiredDesignation',
      'currentJobPosition',
      'candidateExperience',
      'educationType',
      'city',
      'aadhaarMobile',
      'currentMonthlySalary',
      'educationDegree',
      'educationBranch',
      'desiredSalary',
      'gender',
      'language'
    ];
    const sheetData = [headers];
  
    if (customers && customers.length > 0) {
      customers.forEach((item) => {
        const row = headers.map((header) => String(item[header]));
        sheetData.push(row);
      });
  
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'data');
  
      // Save the workbook to a file synchronously
      try {
        XLSX.writeFile(workbook, 'data.xlsx');
      } catch (error) {
        console.error('Error exporting to Excel:', error);
      }
    } else {
      console.error('No data available for export');
    }
  };


  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
        d.createdAt = new Date(d.createdAt);
        return d;
    });
};
  

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: 'contains' },
      fullName: { operator: 'and', constraints: [{ value: null, matchMode: 'startsWith' }] },
      city: { operator: 'and', constraints: [{ value: null, matchMode: 'startsWith' }] },
      whatsappMobile: { value: null, matchMode: 'in' },
      aadhaarMobile: { value: null, matchMode: 'in' },
      createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      desiredDesignation: { operator: 'and', constraints: [{ value: null, matchMode: 'equals' }] },
      currentJobPosition: { operator: 'and', constraints: [{ value: null, matchMode: 'equals' }] },
      candidateExperience: { operator: 'and', constraints: [{ value: null, matchMode: 'startsWith' }] },
    });
    setGlobalFilterValue('');
  };

  const renderHeader = () => {
    return (
      <div className="btlFilterClr justify-content-between">
          <Button className={styles.btnCler} type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                
        
        <div className="p-input-icon-left">
          <span className="p-input-icon-left" style={{ height: '40px'}}>
            <i className="pi pi-search" />
            <InputText
              className="formCon"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
              style={{ height: '40px',fontSize: '16px',borderRadius: '6px',boxShadow:'none'}}
            />
          </span>
        <Button
                type="button"
                icon="pi pi-file-excel"
                className={styles.btnExport}
                onClick={exportToExcel}
            />
        </div>
        
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.city}</span>
      </div>
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        severity="secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        severity="success"
      ></Button>
    );
  };

  const filterFooterTemplate = () => {
    return (
      <div className="px-3 pt-0 pb-3 text-center">Filter by Country</div>
    );
  };
  const dateBodyTemplate = (rowData) => {
    if (rowData.createdAt) {
        const date = new Date(rowData.createdAt);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    return '';
};
const dateFilterTemplate = (options) => {
  return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
};

  const header = renderHeader();

  return (
    <div className="card">
      <DataTable
        value={customers}
        paginator
        showGridlines
        rows={20}
        loading={loading}
        dataKey="id"
        filters={filters}
        globalFilterFields={[
          'fullName',
          'city',
          'desiredDesignation',
          'currentJobPosition',
          'whatsappMobile',
          'aadhaarMobile',
          'candidateExperience',
          'educationType'
        ]}
        header={header}
        emptyMessage="No records found."
      >
        <Column
          field="fullName"
          header="Name"
          filter
          filterPlaceholder="Search by name"
          style={{ minWidth: '12rem' }}
        />
        <Column
          field="whatsappMobile"
          header="Number"
          filter
          filterPlaceholder="Search by number"
          style={{ minWidth: '12rem' }}
        />
        <Column
          field="aadhaarMobile"
          header="Secondry Number"
          filter
          filterPlaceholder="Search by number"
          style={{ minWidth: '12rem' }}
        />
        <Column header="createdAt" filterField="createdAt" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
        <Column
          field="desiredDesignation"
          header="Job Position"
          filter
          filterPlaceholder="Search by Designation"
          style={{ minWidth: '12rem' }}
        />
         <Column
          field="currentJobPosition"
          header="Current Position"
          filter
          filterPlaceholder="Search by Designation"
          style={{ minWidth: '12rem' }}
        />
        <Column
          field="candidateExperience"
          header="Experience"
          filter
          filterPlaceholder="Search by experience"
          style={{ minWidth: '12rem' }}
        />
        <Column
          field="educationType"
          header="Education"
          filter
          filterPlaceholder="Search by education"
          style={{ minWidth: '12rem' }}
        />
        <Column
          header="City"
          filterField="city"
          style={{ minWidth: '12rem' }}
          body={countryBodyTemplate}
          filter
          filterPlaceholder="Search by city"
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          filterFooter={filterFooterTemplate}
        />
      </DataTable>
    </div>
  );
}
