import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import styles from './DataTable.module.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown} from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { CustomerService } from '../../service/JobServiceList';
import '../../../../src/flags.css';
import { Link } from 'react-router-dom';
export default function AdvancedFilterDemo({ data }) {
        const [customers, setCustomers] = useState(data);
        const [filters, setFilters] = useState(null);
        const [loading, setLoading] = useState(false);
        const [globalFilterValue, setGlobalFilterValue] = useState('');
      
        useEffect(() => {
          initFilters();
        }, []);

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.jobMatchedAt = new Date(d.jobMatchedAt);
            return d;
        });
    };
    

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            city: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            whatsapp: { value: null, matchMode: FilterMatchMode.IN },
            aadhaar: { value: null, matchMode: FilterMatchMode.IN },
            starRank: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            sendWhatsapp: { value: null, matchMode: FilterMatchMode.EQUALS },
            sentFCM: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobMatchedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            candidateNotifiedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            candidateAction: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            employerAction: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            ivrApplyCSid: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            ivrApplyCallSid: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            jobCompanyName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            ivrSentApply: { value: null, matchMode: FilterMatchMode.EQUALS },
           
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className={`btlFilterClr ${styles.headerContainer}`}>
                <div className={styles.btnFlot}>
                  <Button className={styles.btnBack} type="button" icon="pi pi-arrow-left" label="Back"/>
                  <Button className={styles.btnCler} type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                </div>
                <span className={`p-input-icon-left ${styles.searchInput}`}>
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    

    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                {/* <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} /> */}
                <span>{rowData.jobCity}</span>
            </div>
        );
    };

    const detailsBodyTemplate = (rowData) => {
        // Extract jobId from the _id field
        const jobId = rowData._id ? rowData._id.jobId : null;
    
        // Define the route with the jobId parameter
        const route = `/details/${jobId}`;
    
        return (
            <Link to={route}>
                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-text" tooltip="Candidate Details" tooltipOptions={{ position: 'bottom' }} />
            </Link>
        );
    };
    
    

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} severity="secondary"></Button>;
    };

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
    };

    const filterFooterTemplate = () => {
        return <div className={`${styles.filterFooter} px-3 pt-0 pb-3 text-center`}>Filter by Country</div>;
    };
    


    const dateBodyTemplate = (rowData) => {
        if (rowData.jobMatchedAt) {
            const date = new Date(rowData.jobMatchedAt);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return '';
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };
    
    const datenotifiedBodyTemplate = (rowData) => {
        if (rowData.candidateNotifiedAt) {
            const date = new Date(rowData.candidateNotifiedAt);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return '';
    };

    const datenotifiedFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };
    
    // const balanceBodyTemplate = (rowData) => {
    //     return formatCurrency(rowData.balance);
    // };

    // const balanceFilterTemplate = (options) => {
    //     return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
    // };

    // const statusBodyTemplate = (rowData) => {
    //     return <Tag value={rowData.verifiedEmployer} severity={getSeverity(rowData.verifiedEmployer)} />;
    // };

    // const statusFilterTemplate = (options) => {
    //     return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    // };

    // const statusItemTemplate = (option) => {
    //     return <Tag value={option} severity={getSeverity(option)} />;
    // };

    // const activityBodyTemplate = (rowData) => {
    //     return <ProgressBar value={rowData.activity} showValue={false} style={{ height: '6px' }}></ProgressBar>;
    // };

    // const activityFilterTemplate = (options) => {
    //     return (
    //         <React.Fragment>
    //             <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
    //             <div className="flex align-items-center justify-content-between px-2">
    //                 <span>{options.value ? options.value[0] : 0}</span>
    //                 <span>{options.value ? options.value[1] : 100}</span>
    //             </div>
    //         </React.Fragment>
    //     );
    // };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.sendWhatsapp, 'text-red-500 pi-times-circle': !rowData.sendWhatsapp })}></i>;
    };

    const verifiedFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="verified-filter" className="font-bold">
                    whatsapp
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const verifiedFCMBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.sentFCM, 'text-red-500 pi-times-circle': !rowData.sentFCM })}></i>;
    };

    const verifiedFCMFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="verified-filter" className="font-bold">
                    FCM
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };
    const verifiedreminderBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.ivrSentApply, 'text-red-500 pi-times-circle': !rowData.ivrSentApply })}></i>;
    };

    const verifiedreminderFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="verified-filter" className="font-bold">
                    FCM
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={customers} paginator showGridlines rows={20} loading={loading} dataKey="id"
                    filters={filters} globalFilterFields={['fullName', 'jobCity','alternateNo', 'whatsappMobile','designation', 'TotalApplied','shortlisted','interview','rejectedByEmployer','rejectedByCandidate','totalMatchedCandidates','pendingFromCandidate','pendingFromEmployer', 'companyName']} header={header}
                    emptyMessage="No records found.">
                <Column body={detailsBodyTemplate} style={{ textAlign: 'center', width: '3rem' }}/>
                <Column field="fullName" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                <Column field="starRank" header="Rank" filter filterPlaceholder="Search by Rank" style={{ minWidth: '12rem', textAlign: 'center' }} />
                <Column field="sendWhatsapp" header="Whatsapp" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem', textAlign: 'center' }} body={verifiedBodyTemplate} filter filterElement={verifiedFilterTemplate} />
                <Column field="sentFCM" header="FCM" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verifiedFCMBodyTemplate} filter filterElement={verifiedFCMFilterTemplate} />
                <Column field="whatsapp" header="Number" filter filterPlaceholder="Search by number" style={{ minWidth: '12rem' }} />
                <Column field="aadhaar" header="Secondry Number" filter filterPlaceholder="Search by number" style={{ minWidth: '12rem' }} />
                <Column field="candidateAction" header="Candidate Action" filter filterPlaceholder="Search by candidate action" style={{ minWidth: '12rem' }} />
                <Column field="employerAction" header="Employer Action" filter filterPlaceholder="Search by employer action" style={{ minWidth: '12rem' }}body={(rowData) => (rowData.employerAction ? rowData.employerAction : '-')}/>
                <Column field="jobCompanyName" header="Company Name" filter filterPlaceholder="Search by company name" style={{ minWidth: '12rem' }} />
                <Column header="Matched On" filterField="jobMatchedAt" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column field="ivrSentApply" header="Reminder Call" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verifiedreminderBodyTemplate} filter filterElement={verifiedreminderFilterTemplate} />
                <Column field="ivrApplyCSid" header="No of reminders " filter filterPlaceholder="Search by no of reminders" style={{ minWidth: '12rem' }} />
                <Column field="ivrApplyCallSid" header="Call Status " filter filterPlaceholder="Search by call status" style={{ minWidth: '12rem' }} />
                {/* <Column header="Notified On" filterField="candidateNotifiedAt" dataType="date" style={{ minWidth: '10rem' }} body={datenotifiedBodyTemplate} filter filterElement={datenotifiedFilterTemplate} /> */}
                <Column header="Job City" filterField="jobCity" style={{ minWidth: '12rem' }} body={countryBodyTemplate}
                    filter filterPlaceholder="Search by city"  filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate} filterFooter={filterFooterTemplate} />
               </DataTable>
        </div>
    );
}
