import React from 'react';
import{ Col, Container, Row } from 'react-bootstrap'  
import rightAro from '../../assets/images/rightArrow.svg'
import styles from './Dashboard.module.scss'
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import ReactChartLine from '../Chart/ReactChartLine/ReactChartLine';
import ReactChartDount from '../Chart/ReactChartDounat/ReactChartDounat'
import { useDateRange } from '../context/ DateRangeContext'; // Import the useDateRange hook
import { getDashboard } from '../../services/api/admin';
import { toast } from 'react-toastify';
import { showLoader, hideLoader, setCookie } from '../../helpers/index';
import { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
const Dashboard = () => {
  const { selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate } = useDateRange();
  const [data, setData] = useState();
  useEffect(() => {
    getData();
}, [selectedStartDate, selectedEndDate]);
console.log('startdate', selectedStartDate)
console.log('end date', selectedEndDate)
  const getData = async () => {
    try {
        showLoader();
        let params = {
            startDate: selectedStartDate,
            endDate: selectedEndDate
        }
        let {
            data: {
                data
            }
        } = await getDashboard(params);
        hideLoader();
        setData(data);

    } catch (error) {
        hideLoader();
    }
}
const countGenderData = data && data.countGender ? data.countGender : [];
let chartDataLine;

if (data && data.candidateWeeklyCount) {
  chartDataLine = [["Weekly", "Count"]];
  data.candidateWeeklyCount.forEach((item) => {
    chartDataLine.push([item.date, item.count]);
  });
} else {
  chartDataLine = [["Weekly", "Count"]];
  chartDataLine.push(["Loading...", 0]);
}



const formatDistance = (date) => {
  const distance = formatDistanceToNow(date, { addSuffix: true, includeSeconds: true });
  return distance.replace('about ', '').replace('ago', '');
};

  
const chartData = [
  ['Gender', 'Percentage'],
  ['Male', countGenderData[0]?.malePercentage || 0],
  ['Female', countGenderData[0]?.femalePercentage || 0],
];
  return (
  <HomeLayout>
    <div className={styles.mainContent}>
      <Container>
        <Row>
          <Col md={7}>
            <div className={styles.boxContent}>
              <h4>Insights</h4>
              <Row>
                <Col md={4}>
                  <div className={styles.smallBox}>
                    <h3>Candidates</h3>
                    {data && data.candidate && (
                      <h2 style={{ color: '#F1C40F' }}> 
                        {data.candidate.totalCount} 

                       <span style={{ color: data.candidate.rangeCount === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.candidate.rangeCount > 0 ? `+${data.candidate.rangeCount}` : '0'}
                    </span>
                      </h2>
                    )}
                  </div>
                </Col>
                <Col md={4} className={styles.paddBoth}>
                  <div className={styles.smallBox}>
                    <h3>Employers</h3>
                    {data && data.employers && (
                    <h2 style={{color: '#9B59B6'}}>{data.employers.totalCount}  

                    <span style={{ color: data.employers.rangeCount === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.employers.rangeCount > 0 ? `+${data.employers.rangeCount}` : '0'}
                    </span>
                    </h2> )}
                  </div>
                 
                </Col>
                <Col md={4}>
                  <div className={styles.smallBox}>
                    <h3>Jobs</h3>
                    {data && data.jobs && (
                    <h2 style={{color: '#3498DB'}}>{data.jobs.totalCount}

                    <span style={{ color: data.jobs.rangeCount === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.jobs.rangeCount > 0 ? `+${data.jobs.rangeCount}` : '0'}
                    </span>
                     </h2>
                    )}
                  </div>
                </Col>
                </Row>
                <Row className={styles.marBot}>
                <Col md={4}>
                  <div className={styles.smallBox}>
                    <h3>Total Vaccancy</h3>
                    {data && data.Vaccancy && data.Vaccancy.totalVaccancies && data.Vaccancy.totalVaccancies[0] && (
                    <h2 style={{ color: '#FF6B81' }}>
                        {data.Vaccancy.totalVaccancies[0].total}{' '} 
                        {data.Vaccancy.rangeVaccancy && data.Vaccancy.rangeVaccancy[0] && data.Vaccancy.rangeVaccancy[0].total !== undefined ? (
                        <>
          
                          <span style={{ color: data.Vaccancy.rangeVaccancy[0].total === 0 ? '#9e9d9d' : '#66ef5e' }}>
                            {data.Vaccancy.rangeVaccancy[0].total !== 0 ? `+${data.Vaccancy.rangeVaccancy[0].total}` : '0'}
                          </span>
                        </>
                      ) : (
                        // Handle the case where data.Vaccancy.rangeVaccancy is an empty array
                        <span style={{ color: '#9e9d9d' }}>0</span>
                      )}
                    </h2>
                    )}
                  </div>
                </Col>
                <Col md={4} className={styles.paddBoth}>
                  <div className={styles.smallBox}>
                    <h3>VIP Card </h3>
                    {data && data.VipCard && (
                    // <h2 style={{color: '#1AB799'}}>{data.VipCard.totalVipCard} <span>+{data.VipCard.rangeVipCard}</span></h2>
                    <h2 style={{ color:'#1AB799'}}>
                    {data.VipCard.totalVipCard}  
                    <span style={{ color: data.VipCard.rangeVipCard === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.VipCard.rangeVipCard > 0 ? `+${data.VipCard.rangeVipCard}` : '0'}
                    </span>
                  </h2>

                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <div className={styles.smallBox}>
                    <h3>CV Download </h3>
                    {data && data.CV && (
                    <h2 style={{color: '#E67E22'}}>{data.CV.totalCV}  
                    
                    <span style={{ color: data.CV.rangeCV === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.CV.rangeCV > 0 ? `+${data.CV.rangeCV}` : '0'}
                    </span>
                    </h2>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={5}>
            <div className={styles.boxContent}>
              <h4>Candidates</h4>
              
              <div className={styles.ChartLIne}>
              <ReactChartLine data={chartDataLine} />
                </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className={styles.boxContent}>
              <h4>Recent Jobs</h4>
              <a href="/joblist/Live" className={styles.viewAllbutton}>View All <img src={rightAro} /></a>
              <div className={styles.candidateList}>
              {data && data.recentJobs ? (
                  <ul className={styles.noticeList}>
                    
                      {data.recentJobs.map((job) => (
                          <li key={job._id}>
                              <a href="#">
                              <span className={styles.dot} style={{ backgroundColor: job.jobStatus === 'Pending' ? '#ff6600' : 'green' }}></span>
                              <span className={styles.name} style={{ color: job.jobStatus === 'Live' ? 'green' : '#ff6600' }}>{job.position}</span>
                                <span className={styles.desc}>{job.companyNameOnInvoice} - {job.jobCity}</span>
                                <span className={styles.city}>{formatDistance(new Date(job.createdAt))} ago</span>
                                {/* <span className={styles.time}>{formatDistanceToNow(new Date(job.createdAt))} ago</span>  */}
                              </a>
                          </li>
                      ))}
                      
                  </ul>
              ) : (
                <p className={styles.loadingData}>Loading recent jobs...</p>
            )}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className={styles.boxContent} style={{padding:'0px'}}>
              <div className={styles.headSec}>
                <h4>Candidate</h4>
                <button className={styles.viewAllbutton}>View All <img src={rightAro}/></button>
              </div>
              {data && data.candidate && (
              <div className={styles.chartDiv}>
                <div className={styles.totalNo}>
                    <h3>Total<span>{data.countGender[0].totalCandidates}</span></h3>
                </div>
                <ReactChartDount data={chartData} />

                <div className={styles.malefemaleCoint}>
                  <ul>
                    <li>
                      <div className={styles.male}>
                        <h3>Male <span></span> </h3>
                        <h5> {data.countGender[0].maleCandidates}{' ' } <span>{data.countGender[0].malePercentage.toFixed(2)}%</span></h5>
                      </div>
                    </li>
                    <li>
                    <div className={styles.female}>
                        <h3>Female  <span></span></h3>
                        <h5> {data.countGender[0].femaleCandidates}{' '} <span>{data.countGender[0].femalePercentage.toFixed(2)}%</span></h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className={styles.boxContent}>
            <h4>Recruitment Funnel</h4>
              <button className={styles.viewAllbutton}>View All <img src={rightAro} /></button>
              <div className={styles.content}>
                {data && data.Applied && data.Shortlisted && data.jobMatched && data.Interview && data.JobOffers && data.Hired && (
                  <div className={styles.progress}>
                    {/* <div className={styles.progressBar} style={{ width: `${(data.Applied.totalApplied / data.jobMatched.totalCount) * 100}%`, background: '#FD7E14' }}>
                      <span className={styles.srOnly}>{Math.round((data.Applied.totalApplied / data.jobMatched.totalCount) * 100)}%</span>
                    </div> */}
                    <div className={styles.progressBar} style={{ width: `${(data.Shortlisted.totalShortlisted / data.Applied.totalApplied) * 100}%`, background: '#3498DB' }}>
                      <span className={styles.srOnly}>{Math.round((data.Shortlisted.totalShortlisted / data.Applied.totalApplied) * 100)}%</span>
                    </div>
                    <div className={styles.progressBar} style={{ width: `${(data.interviewsAll.totalCount / data.Shortlisted.totalShortlisted) * 100}%`, background: '#48A9F8' }}>
                      <span className={styles.srOnly}>{Math.round((data.interviewsAll.totalCount / data.Shortlisted.totalShortlisted) * 100)}%</span>
                    </div>
                    <div className={styles.progressBar} style={{ width: `${(data.JobOffers.totalJobOffered / data.interviewsAll.totalCount) * 100}%`, background: '#8BC740' }}>
                      <span className={styles.srOnly}>{Math.round((data.JobOffers.totalJobOffered / data.interviewsAll.totalCount) * 100)}%</span>
                    </div>
                    <div className={styles.progressBar} style={{ width: `${(data.Hired.totalHired / data.JobOffers.totalJobOffered) * 100}%`, background: '#A5BEFF' }}>
                      <span className={styles.srOnly}>{Math.round((data.Hired.totalHired / data.JobOffers.totalJobOffered) * 100)}%</span>
                    </div>
                  </div>
                )}
                <div className={styles.listAction}>
                  <ul>
                    <li>Total Application</li>
                    <li>Applied <span style={{ color: '#FD7E14' }}>{data && data.Applied ? data.Applied.totalApplied : 0} </span></li>
                    <li>Shortlisted <span style={{ color: '#3498DB' }}>{data && data.Shortlisted ? data.Shortlisted.totalShortlisted : 0} ({data && data.Applied ? Math.round((data.Shortlisted.totalShortlisted / data.Applied.totalApplied) * 100) : 0}%)</span></li>
                    <li>Interview <span style={{ color: '#48A9F8' }}>{data && data.interviewsAll ? data.interviewsAll.totalCount : 0} ({data && data.Interview ? Math.round((data.interviewsAll.totalCount / data.Shortlisted.totalShortlisted) * 100) : 0}%)</span></li>
                    <li>Job Offered <span style={{ color: '#8BC740' }}>{data && data.JobOffers ? data.JobOffers.totalJobOffered : 0} ({data && data.interviewsAll ? Math.round((data.JobOffers.totalJobOffered /data.interviewsAll.totalCount) * 100) : 0}%)</span></li>
                    <li>Hired <span style={{ color: '#A5BEFF' }}>{data && data.Hired ? data.Hired.totalHired : 0} ({data && data.JobOffers ? Math.round((data.Hired.totalHired / data.JobOffers.totalJobOffered) * 100) : 0}%)</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className={styles.boxNewSec}>
              <h4>Action  </h4>
              <ul>
                <li>
                {data && data.jobMatched && (
                  <div className={styles.smallBox}>
                    <h3>Total Matched </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.jobMatched.totalCount} 
                    {/* <span>+{data.jobMatched.rangeCount}</span> */}
                    <span style={{ color: data.jobMatched.rangeCount === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.jobMatched.rangeCount > 0 ? `+${data.jobMatched.rangeCount}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
               
                <li>
                {data && data.Applied && (
                  <div className={styles.smallBox}>
                    <h3>Applied By Candidates </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.Applied.totalApplied}
                     
                     <span style={{ color: data.Applied.rangeApplied === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.Applied.rangeApplied > 0 ? `+${data.Applied.rangeApplied}` : '0'}
                    </span>
                     </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.paidApplyTotal && (
                  <div className={styles.smallBox}>
                    <h3>Paid Apply By Candidates </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.paidApplyTotal}
                     
                     <span style={{ color: data.paidApplyDay === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.paidApplyDay > 0 ? `+${data.paidApplyDay}` : '0'}
                    </span>
                     </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.RejectedByCandidates && (
                  <div className={styles.smallBox}>
                    <h3>Reject By Candidates</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.RejectedByCandidates.totalRejectedByCandidates}
                     <span style={{ color: data.RejectedByCandidates.rangeRejectedByCandidates === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.RejectedByCandidates.rangeRejectedByCandidates > 0 ? `+${data.RejectedByCandidates.rangeRejectedByCandidates}` : '0'}
                    </span>
                     </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.RejectedBySystem && (
                  <div className={styles.smallBox}>
                    <h3>Reject By System </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.RejectedBySystem.totalRejectedBySystem} 
                    <span style={{ color: data.RejectedBySystem.rangeRejectedBySystem === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.RejectedBySystem.rangeRejectedBySystem> 0 ? `+${data.RejectedBySystem.rangeRejectedBySystem}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.RejectedBySystem && (
                  <div className={styles.smallBox}>
                    <h3>Shortlist by Employer</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.Shortlisted.totalShortlisted} 
                    <span style={{ color: data.Shortlisted.rangeShortlisted === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.Shortlisted.rangeShortlisted> 0 ? `+${data.Shortlisted.rangeShortlisted}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.RejectedByEmployer && (
                  <div className={styles.smallBox}>
                    <h3>Reject By Employer </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.RejectedByEmployer.totalRejectedByEmployer} 
                    <span style={{ color: data.RejectedByEmployer.rangeRejectedByEmployer === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.RejectedByEmployer.rangeRejectedByEmployer> 0 ? `+${data.RejectedByEmployer.rangeRejectedByEmployer}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
              <li>
              {data && data.interviewsAll && (
                  <div className={styles.smallBox}>
                    <h3>Total Interview </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.interviewsAll.totalCount} 
                   
                    <span style={{ color: data.interviewsAll.rangeCount === 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.interviewsAll.rangeCount> 0 ? `+${data.interviewsAll.rangeCount}` : '0'}
                    </span>
                    </h2> 
                  </div>
              )}
                </li>
                <li>
                  {data && data.DirectCall && (
                  <div className={styles.smallBox}>
                    <h3>Interview (Quick Call) </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.DirectCall.totalDirectCall}
                     <span style={{ color: data.DirectCall.rangeDirectCall=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.DirectCall.rangeDirectCall> 0 ? `+${data.DirectCall.rangeDirectCall}` : '0'}
                    </span>
                     </h2> 
                  </div>
                  )}
                </li>
                <li>
                {data && data.CallByScheduling && (
                  <div className={styles.smallBox}>
                    <h3>Interview (Schedule)</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.CallByScheduling.totalCallByScheduling} 
                    
                    <span style={{ color: data.CallByScheduling.rangeCallByScheduling=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.CallByScheduling.rangeCallByScheduling> 0 ? `+${data.CallByScheduling.rangeCallByScheduling}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.CallByJobsgaar && (
                  <div className={styles.smallBox}>
                    <h3>Interview (In App)</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.CallByJobsgaar.totalCallJobsgaar} 
                    <span style={{ color: data.CallByJobsgaar.rangeCallJobsgaar=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.CallByJobsgaar.rangeCallJobsgaar> 0 ? `+${data.CallByJobsgaar.rangeCallJobsgaar}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.Interview && (
                  <div className={styles.smallBox}>
                    <h3>Interview Complete </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.Interview.totalInterviewCompleted} 
                    <span style={{ color: data.Interview.rangeInterviewCompleted=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.Interview.rangeInterviewCompleted> 0 ? `+${data.Interview.rangeInterviewCompleted}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className={styles.boxNewSec}>
              <h4>Digital CV </h4>
              <ul>
                <li>
                  {data && data.candidateCreatedBy && (
                  <div className={styles.smallBox}>
                    <h3>CV (App)</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.candidateCreatedBy.app.totalCreatedByApp} 
                    <span style={{ color: data.candidateCreatedBy.app.rangeCretedByApp=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.candidateCreatedBy.app.rangeCretedByApp> 0 ? `+${data.candidateCreatedBy.app.rangeCretedByApp}` : '0'}
                    </span>
                    </h2> 
                  </div>
                  )}
                </li>
                <li>
                {data && data.candidateCreatedBy && (
                  <div className={styles.smallBox}>
                    <h3>CV (Web)</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.candidateCreatedBy.web.totalCreatedByweb} 
                    <span style={{ color: data.candidateCreatedBy.web.rangeCretedByweb=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.candidateCreatedBy.web.rangeCretedByweb> 0 ? `+${data.candidateCreatedBy.web.rangeCretedByweb}` : '0'}
                    </span>
                    </h2> 
                  </div>
                  )}
                </li>
                <li>
                {data && data.candidateCreatedBy && (
                  <div className={styles.smallBox}>
                    <h3>CV (WhatsApp)</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.candidateCreatedBy.whatsappbot.totalCreatedBywhatsappbot} 
                    <span style={{ color: data.candidateCreatedBy.whatsappbot.rangeCretedBywhatsappbot=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.candidateCreatedBy.whatsappbot.rangeCretedBywhatsappbot> 0 ? `+${data.candidateCreatedBy.whatsappbot.rangeCretedBywhatsappbot}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className={styles.boxNewSec}>
              <h4>Other </h4>
              <ul>
                <li>
                {data && data.candidateCompleted && (
                  <div className={styles.smallBox}>
                    <h3>Complete Candidate  </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.candidateCompleted.totalCandidatesComplete} 
                    {/* <span>+{data.candidateCompleted.rangeCandidatesComplete}</span> */}
                    <span style={{ color: data.candidateCompleted.rangeCandidatesComplete=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.candidateCompleted.rangeCandidatesComplete> 0 ? `+${data.candidateCompleted.rangeCandidatesComplete}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.employers && (
                  <div className={styles.smallBox}>
                    <h3>Total Employer </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.employers.totalCount} 
                    <span style={{ color: data.employers.rangeCount=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.employers.rangeCount> 0 ? `+${data.employers.rangeCount}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.employerCompleted && (
                  <div className={styles.smallBox}>
                    <h3>Complete Employer </h3>
                    <h2 style={{color: '#F1C40F'}}>{data.employerCompleted.totalEmployersComplete} 
                    <span style={{ color: data.employerCompleted.rangeEmployersComplete=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.employerCompleted.rangeEmployersComplete> 0 ? `+${data.employerCompleted.rangeEmployersComplete}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                {data && data.employerVerfied && (
                  <div className={styles.smallBox}>
                             {/* api needs to be created for this */}
                    <h3>Verified Employer</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.employerVerfied.totalEmployersVerified} 
                    <span style={{ color: data.employerVerfied.rangeEmployersVerified=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.employerVerfied.rangeEmployersVerified> 0 ? `+${data.employerVerfied.rangeEmployersVerified}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                <li>
                   {data && data.repeatEmployers && (
                  <div className={styles.smallBox}>
                    <h3>Repeat Employers</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.repeatEmployers.totalRepeatEmployers[0].count} 
                    <span style={{ color: data.repeatEmployers.rangeRepeatEmployers[0].count=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.repeatEmployers.rangeRepeatEmployers[0].count> 0 ? `+${data.repeatEmployers.rangeRepeatEmployers[0].count}` : '0'}
                    </span>
                    </h2>  
                  </div>
                   )}
                </li>
                <li>
                {data && data.jobs && (
                   <div className={styles.smallBox}>
                   <h3>Total Jobs</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.jobs.totalCount} 
                    <span style={{ color: data.jobs.rangeCount=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.jobs.rangeCount> 0 ? `+${data.jobs.rangeCount}` : '0'}
                    </span>
                    </h2>
                    </div>
                    )}
                    
                </li>
                <li>
                {data && data.jobsByRepeatEmployers && (
                  <div className={styles.smallBox}>
                    <h3>Jobs by Repeat Employers</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.jobsByRepeatEmployers.totaljobsByRepeatEmployers[0].totalJobsByRepeatEmployers} 
                    <span style={{ color: data.jobsByRepeatEmployers.rangejobsByRepeatEmployers[0].totalJobsByRepeatEmployers=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.jobsByRepeatEmployers.rangejobsByRepeatEmployers[0].totalJobsByRepeatEmployers> 0 ? `+${data.jobsByRepeatEmployers.rangejobsByRepeatEmployers[0].totalJobsByRepeatEmployers}` : '0'}
                    </span>
                    </h2>  
                  </div>
                )}
                </li>
                <li>
                  <div className={styles.smallBox}>
                    <h3>Avg. Job/Employers</h3>
                    {data && data.jobs && data.employerCompleted && (
                    <h2 style={{ color: '#F1C40F' }}>
                      {isNaN(data.jobs.totalCount / data.employerCompleted.totalEmployersComplete) ?
                        0 : (data.jobs.totalCount / data.employerCompleted.totalEmployersComplete).toFixed(2)}
                      <span style={{ color: isNaN(data.jobs.rangeCount / data.employerCompleted.rangeEmployersComplete) || data.employerCompleted.rangeEmployersComplete === 0 ? '#9e9d9d' : '#66ef5e' }}>
                        {isNaN(data.jobs.rangeCount / data.employerCompleted.rangeEmployersComplete) || data.employerCompleted.rangeEmployersComplete === 0
                          ? '0'
                          : `+${(data.jobs.rangeCount / data.employerCompleted.rangeEmployersComplete).toFixed(2)}`}
                      </span>
                    </h2>
                  )}

                  </div>
                </li>
                <li>

                  <div className={styles.smallBox}>
                    <h3>Avg. Vaccancy/Employers</h3>
                    {data?.Vaccancy?.totalVaccancies && data?.employerCompleted?.totalEmployersComplete && (
    <h2 style={{ color: '#F1C40F' }}>
        {Math.round(data.Vaccancy.totalVaccancies[0].total / data.employerCompleted.totalEmployersComplete)}{' '}
        <span style={{ color: data?.Vaccancy?.rangeVaccancy && data.Vaccancy.rangeVaccancy[0] && data?.employerCompleted?.rangeEmployersComplete
          ? (Math.round(data.Vaccancy.rangeVaccancy[0].total / data.employerCompleted.rangeEmployersComplete) === 0 ? '#9e9d9d' : '#66ef5e')
          : '#9e9d9d' }}>
          {data?.Vaccancy?.rangeVaccancy && data.Vaccancy.rangeVaccancy[0] && data?.employerCompleted?.rangeEmployersComplete
            ? (Math.round(data.Vaccancy.rangeVaccancy[0].total / data.employerCompleted.rangeEmployersComplete) !== 0
              ? `+${Math.round(data.Vaccancy.rangeVaccancy[0].total / data.employerCompleted.rangeEmployersComplete)}`
              : '0')
            : '0'}
        </span>
    </h2>
)}



                  </div>
                </li>
                <li>
                {data && data.NotificationSent && (
                  <div className={styles.smallBox}>
                    <h3>Notification Sent</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.NotificationSent.totalNotificationSent} 
                    <span style={{ color: data.NotificationSent.rangeNotificationSent=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.NotificationSent.rangeNotificationSent> 0 ? `+${data.NotificationSent.rangeNotificationSent}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
                
                <li>
                {data && data.DailyLogin && (
                  <div className={styles.smallBox}>
                    <h3>Daily Login</h3>
                    <h2 style={{color: '#F1C40F'}}>{data.DailyLogin.totalDailyLogin} 
                    {/* <span>+{data.DailyLogin.rangeDailyLogin}</span> */}
                    <span style={{ color: data.DailyLogin.rangeDailyLogin=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.DailyLogin.rangeDailyLogin> 0 ? `+${data.DailyLogin.rangeDailyLogin}` : '0'}
                    </span>
                    </h2> 
                  </div>
                )}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={styles.boxNewSec}>
              <h4>Job Offer </h4>
              <ul>
              <li>
                  <div className={styles.smallBox}>
                    <h3>Candidate In Review  </h3>
                    {data && data.InReview && (
                    <h2 style={{color: '#F1C40F'}}>{data.InReview.totalInReview} 

                    <span style={{ color: data.InReview.rangeInReview=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.InReview.rangeInReview> 0 ? `+${data.InReview.rangeInReview}` : '0'}
                    </span>
                    </h2> 
                    )}
                  </div>
                </li>
                <li>
                  <div className={styles.smallBox}>
                    <h3>Job Offer Sent</h3>
                    {data && data.JobOffers && (
                    <h2 style={{color: '#F1C40F'}}>{data.JobOffers.totalJobOffered} 
                    <span style={{ color: data.JobOffers.rangeJobOffered=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.JobOffers.rangeJobOffered> 0 ? `+${data.JobOffers.rangeJobOffered}` : '0'}
                    </span>
                    </h2> 
                    )}
                  </div>
                </li>
                <li>
                  <div className={styles.smallBox}>
                    <h3>Job Offer Accepected </h3>
                    {data && data.JobOffersAccepted && (
                    <h2 style={{color: '#F1C40F'}}>{data.JobOffersAccepted.totalOfferAccepted} 
                    <span style={{ color: data.JobOffersAccepted.rangeOfferAccepted=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.JobOffersAccepted.rangeOfferAccepted> 0 ? `+${data.JobOffersAccepted.rangeOfferAccepted}` : '0'}
                    </span>
                    </h2> 
                    )}
                  </div>
                </li>
                <li>
                  <div className={styles.smallBox}>
                    <h3>Hired </h3>
                    {data && data.Hired && (
                     <h2 style={{color: '#F1C40F'}}>{data.Hired.totalHired} 
                     <span style={{ color: data.Hired.rangeHired=== 0 ? '#9e9d9d' : '#66ef5e' }}>
                      {data.Hired.rangeHired> 0 ? `+${data.Hired.rangeHired}` : '0'}
                    </span>
                     </h2> 
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </HomeLayout> 
  );
};

export default Dashboard;
