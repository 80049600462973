import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeLayout from '../layouts/HomeLayout/HomeLayout';
import Login from '../components/Login/Login';
import Dashboard from '../components/Dashboard/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import Employers from '../components/Employer/EmployerList';
import JobList from '../components/JobList/JobList';
import JobMatchDetail from '../components/JobList/JobMatchList'
import Candidate from '../components/Candidate/Candidate';
import CandidateDetail from '../components/Candidate/CandidateDetail';
// import CandidateDetail from '../components/Candidate/CandidateDetail';
//components
import DatePicker from '../components/common/DatePicker/DatePicker';
import SidebarCommon from '../components/common/SidebarCommon/SideBarCommon';
import UserSearch from '../components/UserSearch/UserSearch';
import LeaderBoard from '../components/LeaderBoard/LeaderShipBoard';
import { Leaderboard } from '@mui/icons-material';
import EmployerDetail from '../components/Employer/EmployerDetail';
// import DataGrid from '../components/DataTable/DataGrid/DataGrid';

function RouterComponent() {
    return (
        <Router>
          
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/details/:jobId' element={<ProtectedRoute><JobMatchDetail /></ProtectedRoute>} exact />
                <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path='Login' element={<Login/>}/>
                <Route path='employers' element={<ProtectedRoute><Employers /></ProtectedRoute>} />
                <Route path='employerDetail/:id' element={<ProtectedRoute><EmployerDetail/></ProtectedRoute>}/>
                {/* <Route path='joblist'element={<ProtectedRoute><JobList /></ProtectedRoute>} /> */}
                <Route path='/joblist'>
                    <Route index element={<ProtectedRoute><JobList /></ProtectedRoute>} />
                    <Route path=':path' element={<ProtectedRoute><JobList /></ProtectedRoute>} />
                </Route>
                <Route path='UIDSearch' element={<ProtectedRoute><UserSearch/></ProtectedRoute>}/>
                <Route path='leaderShipBoard' element={<ProtectedRoute><LeaderBoard/></ProtectedRoute>}/>
                <Route path='datepiker' element={<DatePicker/>}/>
                <Route path='Candidate'element={<ProtectedRoute><Candidate /></ProtectedRoute>} />
                <Route path='candidateDetails' element={<ProtectedRoute><CandidateDetail/></ProtectedRoute>}/>
                <Route path='sidebarcommon' element={<SidebarCommon/>}/>
                {/* <Route path='sidebarNew' element={<MyProSidebar.jsx/>}/> */}
                {/* <Route path='datagrid' element={<DataGrid/>}/> */}
            </Routes>
        </Router>
    );
}

export default RouterComponent;